.special-head {
    .sliderArea {
        .mainvisual {
            overflow-x: hidden;
            position: relative;
            .mainvisual-inner {
                position: relative;
                margin:0 auto;
                max-width: 960px;
                .swiper-container {
                    width: 100%;
                    height: 100%;
                    overflow: visible;
                    z-index: 1;
                }
            }
            img {
                width: 100%;
                vertical-align:bottom;
            }
            .special-slider {
                &.swiper-container-horizontal>.swiper-pagination-bullets {
                    bottom: -60px;
                    .flexbox();
                    justify-content: center;
                    .swiper-pagination-bullet {
                        width: 100px;
                        height: 50px;
                        border-radius: 0;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        display:block;
                        background-color:transparent;
                        opacity: 0.5;
                    }
                    .swiper-pagination-bullet-active {
                        opacity: 1;
                    }
                }
            }
            .opacity-right {
                width: 100%;
                height: 376px;
                position: absolute;
                bottom: 0;
                left: calc(~'50% + 480px');
                z-index: 3;
                background:url(/files/user/images/bg.png);
            }
            .opacity-left {
                width: 100%;
                height: 376px;
                position: absolute;
                bottom: 0;
                right: calc(~'50% + 480px');
                z-index: 3;
                background:url(/files/user/images/bg.png);
            }
            .swiper-button-prev,
            .swiper-button-next {
              fill: #fff;
              stroke: none;
              stroke-width: 0;
              z-index: 10000;
              width: 20px;
              background-color: rbga(0,0,0,0.4;);
              background-image: none;
              text-align: center;
              svg {
                  filter: drop-shadow(0 0 6px rgba(0,0,0,0.7));
              }
            }
            @media screen and (min-width: @breakpoint_tb) {
                .mainvisual-inner {
                    .shop-slider {
                        &.swiper-container-horizontal>.swiper-pagination-bullets {
                            min-width: 960px;
                            left: -160px;
                        }
                    }
                }
            }
            @media screen and (max-width: 640px) {
                .mainvisual-inner {
                    .shop-slider {
                        &.swiper-container-horizontal>.swiper-pagination-bullets {
                            bottom: -38px;
                            .swiper-pagination-bullet {
                                margin: 0 2px;
                                max-width: 70px;
                                height: 30px;
                            }
                        }
                    }
                }
                .swiper-button-prev,
                .swiper-button-next {
                    width: 18px;
                    margin-top: -22px;
                    svg {
                        width: 20px;
                    }
                }
            }
        }
    }
    .special-ttl {
        background: #e6ddcc;
        .special-h1 {
            position: absolute;
            bottom:0;
            background: rgba(0,0,0,0.5);
            width: 100%;
            padding: 20px 15px;
            color: white;
            font-weight: 500;
            font-size: 38px;
            line-height: 1.4;
            .flexbox();
            align-items: flex-start;
            .icon {
                background: #ed797a;
                display: inline-block;
                padding: 0.1em 0.8em;
                margin-right: 0.5em;
                font-size: 30px;
            }
            .maintxt {
                .flex();
            }
        }
        @media screen and (max-width: @breakpoint_tb) {
            .inner-in {
                padding:0;
            }
            img {
                width: 100%;
            }
        }
        @media screen and (max-width: @breakpoint) {
            .special-h1 {
                padding: 0.4em 0.5em;
                font-size: 5vw;
                .icon {
                    font-size: 4vw;
                }
            }
        }
    }
    .special-subttl {
        background: url(/files/user/images/bg-header.png) top center;
        font-weight: 700;
        font-size: 26px;
        padding: 1em 0;
        @media screen and (max-width: @breakpoint) {
            font-size: 4vw;
        }
    }
}
.special-article-list {
    background:url(/files/user/images/bg-special.png) no-repeat 80% -100px;
    @media screen and (max-width: @breakpoint) {
        background-size: 120%;
        background-position: center top ;
    }
    .lead {
        padding:20px 0 50px 0;
        font-size: 16px;
        line-height: 1.8;
        @media screen and (max-width: @breakpoint) {
            font-size: 15px;
            line-height: 1.6;
            padding: 1em 0 1.5em 0;
        }
    }
    .inner-in {
        padding-top: 30px;
        padding-bottom: 30px;
        @media screen and (max-width: @breakpoint) {
            background-position:  45% -50px;
            background-size: 110%;
        }
    }
    .article-list-data {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        justify-items: stretch;
        align-items: stretch;
        @media screen and (max-width: @breakpoint_tb) {
            grid-column-gap: 10px;
            grid-row-gap: 10px;
        }
        @media screen and (max-width: @breakpoint) {
            grid-template-columns: 1fr;
        }
        .article-data-frame {
            border: 2.5px solid #000;
            background: #fff;
            border-radius: 15px;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: @breakpoint) {
                display: flex;
            }
            &.new {
                .article-date:before {
                    background:#fb0000;
                    background-size: 100%;
                    content: "NEW";
                    display: inline-block;
                    color: white;
                    margin-right: 5px;
                    padding: 0.2em 5px;
                }
            }
            .image {
                border-radius: 13px 13px 0 0;
                position: relative;
                height: 190px;
                overflow: hidden;
                text-align: center;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                @media screen and (max-width: @breakpoint) {
                    width: 45%;
                    height : auto;
                    border-radius: 13px 0 0 13px;
                }
            }
            .txt {
                padding: 15px;
                @media screen and (max-width: @breakpoint) {
                    width: 55%;
                }
            }
            .article-date {
                color: #7d7d7d;
                font-size: 12px;
                margin: 0 0 0.5em 0;
            }
            .article-ttl {
                font-size: 22px;
                font-weight: 500;
                margin: 8px 0;
                line-height: 1.4;
                @media screen and (max-width: @breakpoint) {
                    font-size: 16px;
                }
            }
            .article-txt {
                margin: 0 0 10px;
                @media screen and (max-width: @breakpoint) {
                    font-size: 13px;
                }
            }
            .btn_pink {
                width: 70%;
                min-width: 130px;
                margin-bottom:0;
            }
            .image {
                img {
                    width: 100%;
                }
            }
        }
    }
}
.special-article {
    p {
        font-size: 16px;
        margin-bottom: 1em;
    }
    .intro {
        margin: 40px auto;
    }
    .row {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .caption {
        font-size: 12px;
    }
    img {
        margin-bottom: 15px;
        width: 100%;
    }
    h3 {
        font-size: 20px;
        margin-bottom: 0.8em;
    }
    .article {
        padding: 30px 0;
        .col-1, .col-2, .col-3, .col-4, .col-5, .col-6 {
            &.image {
                float:right;
            }
        }
        &:nth-child(even) {
            background:#fffeeb;
        }
        &:nth-child(odd) {
            .col-1, .col-2, .col-3, .col-4, .col-5, .col-6 {
                float:right;
                &.image {
                    float:left;
                }
            }
        }
    }
    .image-s {
        .flexbox();
        div {
            width: 49%;
        }
    }
    .shop-info {
        border: 3px solid black;
        border-radius: 15px;
        background: white;
        padding: 15px 25px;
        margin-bottom: 20px;
        .info-ttl {
            border-bottom: 1px solid #7d7d7d;
            margin-bottom:25px;
            img {
                width:184px;
                margin-bottom:8px;
            }
        }
        .shopname {
            font-size: 22px;
        }
        .tag,.address,.phone {
            font-size: 13px;
            margin-bottom: 0.5em;
        }
    }
    @media screen and (min-width: @breakpoint) {
        .intro {
            .col-4 {
                padding-left: 7%;
            }
        }
    }
    @media screen and (max-width: @breakpoint) {
        .inner-in {
            padding-left: 5%;
            padding-right: 5%;
        }
        p {
            font-size: 14px;
        }
        h3 {
            font-size: 18px;
        }
        .intro {
            margin: 20px auto;
            figure {
                .flexbox();
                align-items: flex-start;
                img,.caption {
                    width: 50%;
                }
                .caption {
                    margin-left: 15px;
                }
            }
        }
        .row {
            margin-top: 10px;
            margin-bottom: 10px;
            .col-1, .col-2, .col-3, .col-4, .col-5, .col-6 {
                &.image {
                    float: none;
                }
            }
        }
        .shop-info {
            padding: 10px 15px;
            margin-bottom: 10px;
            .info-ttl {
                margin-bottom:15px;
                img {
                    max-width:184px;
                    width: 50%;
                }
            }
            .shopname {
                font-size: 18px;
            }
        }
    }
}
.pagenation.special {
    background: #eeeeee;
    .prev,.next,.inactive,.active,.more {
        a {
            background: white;
        }
    }
}