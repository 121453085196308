.bg-slider {	
background-size: cover;	
height: 635px;	
position:relative;
@media screen and (max-width: @breakpoint) {
    height: 25%;
}
}	

.top-about {
    text-align: center;
    background:#fff100;
    font-size: 18px;
    font-weight: 700;
    padding: 0.5em;
    @media screen and (max-width: @breakpoint) {
        font-size: 12px;
    }
    .top-about-btn {
        display:inline-block;
        font-size: 14px;
        font-weight: 400;
        display: inline-block;
        background:white;
        width: 12em;
        text-align: center;
        color:black;
        text-decoration: none;
        padding: 0.25em 0;
        margin-left: 1em;
        border-radius: 5px;
        &:before {
            content: "\e908";
            font-family: 'icon-shokutujin';
            font-size: 11px;
            padding-right: 5px;
            color:#ec5e80;
        }
        &:hover {
            color:white;
            background:#ed797a;
            &:before {
                color:white;
            }
        }
        @media screen and (max-width: @breakpoint) {
            font-size: 12px;
            width: 8em;
        }
    }
}
.header.topPage {
    position: absolute;
    width: 100%;
    .inner-in {
        .clearfix();
    }
    .catch {
        position: absolute;
        top: 50%;
        left:10px;
        margin-top: -12px;
    }
    @media screen and (max-width: @breakpoint_tb) {
        .catch {
            margin-top: -8px;
        }
        .profile {
            background: #fff;
            right: 100px;
            .personal-txt {
                display: block;
            }
        }
    }
    @media screen and (max-width: @breakpoint) {
        height: auto;
        padding-top: 5px;
        .notice {
            left: 10px;
        }
        .catch {
            position: relative;
            text-align:center;
            top: auto;
            left: auto;
            margin: 0 auto 5px;
            width: 70%;
        }
        .profile {
            right: auto;
            left: 10px;
            font-size: 12px;
            .personal-txt {
                display: block;
                line-height: 30px;
            }
        }
    }
}

/* mv */
.mv .inner-out {
    padding-top: 80px;
    position: relative;
}
.mv .catch {
    margin-bottom: 30px;
    text-align: center;
    @media screen and (max-width: @breakpoint) {
        display:none;
    }
}
.mv .mainttl {
    text-align: center;
    margin-top: 20px;
    padding: 0 10%;
    @media screen and (max-width: @breakpoint) {
        width: 100%;
        margin-bottom: 10px;
    }
}
.mv .stamp {
    position: absolute;
    right: 130px;
    top: 230px;
    @media screen and (max-width: @breakpoint_tb) {
        width: 17%;
        top: 35%;
        right: 2%;
    }
    @media screen and (max-width: @breakpoint) {
        display:none;
    }
}
.mv .read {
    text-align: center;
    @media screen and (max-width: @breakpoint) {
        display:none;
    }
}
.mv .mask {
    background: url(/files/user/images/bg-mv-mask.png) top center;
    width: 100%;
    height: 635px;
    position: absolute;
    top: 0;
    z-index: -1;
    @media screen and (max-width: @breakpoint) {
        height: 100%;
    }
}
.search.topPage {
    background:white;
    .linkarea {
        background:none;
        position: relative;
        .inner-in {
            height:20px;
            &:before {
                background:url(/files/user/images/bg-search.png) top center no-repeat;
                content:"";
                display: block;
                width:902px;
                height: 202px;
                top:-70px;
                left: -65px;
                position: absolute;
            }
        }
        .tab {
            position: absolute;
            bottom: 100%;
        }
        @media screen and (max-width: @breakpoint) {
            background-size:180%;
            .inner-in {
                &:before {
                    width: 100%;
                    background-size: 90%;
                    top:-70px;
                    left: 0;
                    height: 70px;
                }
            }
        }
    }
}

/* situation */

.situation {	
    background: url(/files/user/images/bg-situation.png) center bottom no-repeat;	
    width: 580px;
    padding: 20px 30px;
    margin: 0 auto;
    position: relative;
    z-index:100;
    box-sizing: content-box;
    @media screen and (max-width: @breakpoint_tb) {
        background-size: 96%;
        padding: 30px 5%;
        width: 90%;
    }
    @media screen and (max-width: @breakpoint) {
        padding: 0 20px;
        margin-bottom: 15%;
    }
}
.situation .situation-inner {
    margin-left: 5px;
    position: relative;
}
.situation .ttl {
    background: #000;
    border: 5px solid #000;
    border-radius: 15px 15px 0 0;
    padding: 20px 0 15px;
    text-align: center;
    line-height: 1;
    @media screen and (max-width: @breakpoint) {
        border-radius: 10px 10px 0 0;
        padding: 3% 0 2%;
        img {
            width: 80%;
        }
    }
}
.situation .swiper-container1 {
    background: #000;
    border: 5px solid black;
    overflow: hidden;
    border-radius: 0 0 15px 15px;
    @media screen and (max-width: @breakpoint) {
        border: 2px solid;
        border-radius: 0 0 10px 10px;
    }
}
.situation .swiper-slide {
    background: #fff;
    border: 2.5px solid #000;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
}
.situation .swiper-slide:hover {
    cursor: pointer;
}
.situation .swiper-button-prev {
    background-image: url(/files/user/images/arrow-left-01.png);
    background-size: auto;
    top: 50%;
    margin-top: -22px;
    left: -40px;
    @media screen and (max-width: @breakpoint_tb) {
        background-image: url(/files/user/images/arrow-left-01_sp.png);
        background-size: 100%;
        left: -12px;
        margin-top: 12px;
    }
}
.situation .swiper-button-next {
    background-image: url(/files/user/images/arrow-right-01.png);
    background-size: auto;
    top: 50%;
    margin-top: -22px;
    right: -40px;
    @media screen and (max-width: @breakpoint_tb) {
        background-image: url(/files/user/images/arrow-right-01_sp.png);
        background-size: 100%;
        right: -12px;
        margin-top: 12px;
    }
}

.modal_close {
    position: absolute;
    top: 25px;
    right: 25px;
}

/* feature */

.feature {
    background: url(/files/user/images/bg-feature.png) #f5f5f5 top center no-repeat;
    height: 690px;
    position: relative;
    @media screen and (max-width: @breakpoint_tb) {
        background-size: 200%;
        height: 650px;
    }
}
.feature .inner-in {
    padding-top: 80px;
    @media screen and (max-width: @breakpoint_tb) {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 70px;
    }
}
.feature .ttl {
    text-align: center;
    margin-bottom: 30px;
}
.feature .swiper-container2 {
    width: 960px;
    margin: 0 0 25px -30px;
    @media screen and (max-width: @breakpoint_tb) {
        width: 100%;
        margin: 0 0 25px 0;
    }
}
.feature .swiper-slide {
    overflow: hidden;
    border: 2.5px solid #000;
    border-radius: 15px;
    height: auto;
    background: white;
  //transition: all 0.5s ease-in-out;
}
.feature .swiper-slide-prev {
  //transition: all 0.5s ease-in-out;
}
.feature .swiper-slide-active {
    margin-left: 30px;
    @media screen and (max-width: @breakpoint_tb) {
        margin-left: 0;
    }
  //transition: all 0.5s ease-in-out;
}
.feature .swiper-slide-active + div + div + div {
    margin-left: 30px;
  //transition: all 0.5s ease-in-out;
    @media screen and (max-width: @breakpoint_tb) {
        margin-left: 0;
    }
}
.feature .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: -60px;
    @media screen and (max-width: @breakpoint) {
        bottom: 40px;
    }
}
.feature .swiper-button-prev {
    background-image: url(/files/user/images/arrow-left-02.png);
    background-size: auto;
    top: 370px;
    left: calc( ~'50% - 520px');
    @media screen and (max-width: @breakpoint_tb) {
        left: 5px;
        top: 340px;
    }
}
.feature .swiper-button-next {
    background-image: url(/files/user/images/arrow-right-02.png);
    background-size: auto;
    top: 370px;
    right: calc( ~'50% - 520px');
    @media screen and (max-width: @breakpoint_tb) {
        right: 5px;
        top: 340px;
    }
}
.feature .swiper-pagination-bullet-active {
    background: #969696;
}
.feature .image {
    position: relative;
    height: 270px;
    overflow: hidden;
    text-align: center;
    img {
        object-fit: cover;
        width: 100%;
        height: 270px;
    }
    @media screen and (max-width: @breakpoint) {
        img {
            width: 100%;
        }
    }
}
.feature .tag {
    border-radius: 0% 5% 100% 5%;
    font-weight: bold;
    height: 50px;
    padding: 10px 8px;
    position: absolute;
    top: 0;
    width: 50px;
    font-size: 14px;
    text-align: left;
    .tag-txt {
        
    }
}
.feature .single {
    background: #fff75b;
    color: #000;
}
.feature .series {
    background: #2eb6aa;
    color: #fff;
}
.feature .article-ttl {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
}
.feature .subttl {
    padding: 15px;
}
.feature a {
    color: #000;
    text-decoration: none;
}
.feature .feature-list-button {
    float: right;
    background: #ed797a;
    border-radius: 20px;
    @media screen and (max-width: @breakpoint) {
        float:none;
        margin-top: 40px;
        text-align: center;
    }
}
.feature .feature-list-button a {
    padding: 13px 50px;
    display: block;
    color: #fff;
    background: none;
    text-decoration: none;
    @media screen and (max-width: @breakpoint) {
        padding: 5px 20px;
    }
}
.feature .opacity-right {
    width: 100%;
    height: inherit;
    position: absolute;
    top: 0;
    left: calc( ~'50% + 499px' );
    z-index: 2;
    background: rgba(245, 245, 245, 0.6);
    @media screen and (max-width: @breakpoint_tb) {
        left: calc( ~'100% - 30px' );
        height: 520px;
        bottom:0;
        top:auto;
    }
}
.feature .opacity-left {
    width: 100%;
    height: inherit;
    position: absolute;
    top: 0;
    right: calc( ~'50% + 501px' );
    z-index: 2;
    background: rgba(245, 245, 245, 0.6);
    @media screen and (max-width: @breakpoint_tb) {
        right: calc( ~'100% - 30px' );
        height: 520px;
        bottom:0;
        top:auto;
    }
}


/* news */
.news {	
    background: #fff75b;
    padding: 15px 0 30px;
    font-size: 14px;
    .news_ttl {
        text-align: center;
        font-size: 24px;
        font-family:@fontMincho;
        margin-bottom: 0.3em;
    }
    .news_cont {
        border-radius: 20px;
        background:white;
        padding: 10px 20px 5px;
        position: relative
    }
    dl {
        dt {
            position: absolute;
            &:after {
               content:"｜";
               padding-left: 0.4em;
            }
        }
        dd {
            padding-left: 7em;
            margin-bottom: 0.5em;
        }
    }
    a {
        color: black;
    }
    .news_link {
        border-top: 1px dotted #a8a8a8;
        padding-top: 10px;
        text-align: center;
        margin: 10px 0 5px;
        a {
            color: black;
            text-decoration: none;
        }
    }
    @media screen and (min-width: @breakpoint) {
        .news_link {
            position: absolute;
            top: -55px;
            .linkbtn();
            border-top:none;
            padding:0;
            right:0;
        }
    }
    @media screen and (max-width: @breakpoint) {
        font-size: 12px;
        dl {
            border-radius: 10px;
            dt {
                &:after {
                }
            }
            dd {
            }
        }
    }
}

.excuse1-3 {
    .modal_close {
        top: 5px;
        right: 5px;
    }
}