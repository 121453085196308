@breakpoint:768px;
@breakpoint_min:320px;
@breakpoint_tb:960px;
@site_width:1200px;
@site_content_width:1064px;

@colorRed:#ff0000;
@colorOrange:#e8502c;
@colorPink:#ee80a3;
@colorGray:#6d6d6d;
@colorLine:#e9e9e9;
@colorSite:#1e50a2;
@colorNotes:#888888;
@colorYellow:#ffff00;

@bgPink:#fff1ef;
@bgGray:#f7f7f7;

@icon: 'icon-shokutujin';
@fontNormal:'Verdana', 'Arial', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', sans-serif;
@fontMincho: Georgia,'游明朝','Yu Mincho',YuMincho,'Hiragino Mincho ProN','HGS明朝E','メイリオ',Meiryo,serif;