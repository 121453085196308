
.clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

    .flexbox(@justify:space-between) {
      display: -ms-flexbox;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -ms-flex-pack: justify;
      -webkit-box-pack: justify;
      -webkit-justify-content:@justify;
      justify-content:@justify;
      &.alignitem-center {
        align-items: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        -webkit-box-align: center;
      }
    }
    .flex() {
        flex: 1;
        -webkit-flex: 1;
    }
    .alignitem-center() {
        align-items: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        -webkit-box-align: center;
    }
    
    .dotBg(@color:url(/files/user/images/bg-dot-blue.png)) {
        position: relative;
        box-sizing: border-box;
        * {
            position: relative;
        }
        &:after {
            background-image:@color;
            background-position:top right ;
            background-repeat:repeat;
            position: absolute;
            bottom: -10px;
            right: -10px;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            z-index:-1;
            border-radius: 10px;
            @media screen and (max-width: @breakpoint) {
                background-size: 8px;
                bottom: -6px;
                right: -6px;
            }
        }
    }
    
.arrow_box_left(@border:#000000) {
	position: relative;
	background: #ffffff;
	border: 3px solid #000000;
	border-radius: 10px;
	&:after, &:before {
	right: 100%;
	top: 30px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	}
	&:after {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #ffffff;
	border-width: 10px;
	margin-top: -10px;
	}
	&:before {
	border-color: rgba(0, 0, 0, 0);
	border-right-color: #000000;
	border-width: 14px;
	margin-top: -14px;
	}
}


.arrow_box_lighter(@border:#999999) {
	position: relative;
	background: #ffffff;
	border: 1px solid @border;
	border-radius: 2px;
	&:after, &:before {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	}
	&:after {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #ffffff;
	border-width: 4px;
	margin-top: -4px;
	}
	&:before {
	border-color: rgba(0, 0, 0, 0);
	border-right-color: @border;
	border-width: 5px;
	margin-top: -5px;
	}
}

.arrow_box_top(@border:#000000) {
	position: relative;
	background: #ffffff;
	border: 3px solid @border;
	border-radius: 8px;
	&:after, &:before {
	left: 50px;
	bottom: 100%;
	top: auto;
	right: auto;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	}
	&:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ffffff;
	border-width: 8px;
	margin-left: -8px;
	}
	&:before {
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: @border;
	border-width: 12px;
	margin-left: -12px;
	}
}

.linkbtn(@bgcolor:#ed797a,@txtcolor:white) {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	a {
		display: block;
		background: @bgcolor;
		color:@txtcolor;
		text-decoration: none;
		border-radius: 50px;
		font-size: 14px;
		text-align: center;
		padding: 0.5em 2em;
	}
}
.linkbtn_border(@color:#ed797a) {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	.dotBg();
	a {
		display: block;
		background: white;
		color:@color;
		text-decoration: none;
		font-size: 16px;
		text-align: center;
		padding: 1em 2em;
		border-radius: 10px;
		border: 3px solid #000000;
	}
}
