.news-list,
.news-single{
    background:url(/files/user/images/bg-news.png) no-repeat 50px -20px;
    font-size: 16px;
    .inner-in {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .news_cont {
        padding: 10px 0 5px;
        position: relative;
    }
    p {
        margin-bottom: 1em;
    }
    .btn_pink {
        width: ;
        margin: 30px auto;
        a {
            display: inline-block;
        }
    }
    @media screen and (max-width: @breakpoint) {
        background-size: 150%;
        font-size: 14px;
        .inner-in {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .ttl {
            
            img {
                width: 50%;
                max-width: 150px;
            }
        }
    }
}
.news-list {
    .ttl {
        text-align: center;
        margin-bottom: 1em;
    }
    dl {
        dt {
            position: absolute;
            &:after {
               content:"｜";
               padding-left: 0.4em;
            }
        }
        dd {
            padding-left: 7em;
            margin-bottom: 1em;
            padding-bottom: 1em;
            border-bottom: 1px dotted #797c8d;
        }
        a {
            color: black;
        }
    }
    @media screen and (max-width: @breakpoint) {
        dl {
            dt {
                position: relative;
                font-size: 13px;
                &:after {
                    content:"";
                }
            }
            dd {
                padding-left:0;
            }
        }
    }
}
.news-single{
    .ttl {
        text-align: left;
        margin-bottom: 1em;
        line-height: 1.3;
        background: linear-gradient(transparent 70%, #fff100 70%);
    }
}