.shop {
    .shop-data {
        background: url(/files/user/images/bg-stripe.png) repeat;
        .inner-in {
            padding:30px 0 10px;
            @media screen and (max-width: @breakpoint_tb) {
                padding:20px 10px 10px;
            }
            @media screen and (max-width: @breakpoint) {
                padding:40px 10px 10px;
            }
        }
        .shopname {
            font-weight: 400;
            border-bottom: 1px solid #7d7d7d;
            font-size: 30px;
            padding-bottom: 0.4em;
            margin-bottom: 0.4em;
            padding-right: 150px;
            @media screen and (max-width: @breakpoint_tb) {
                font-size: 20px;
            }
            @media screen and (max-width: @breakpoint) {
                padding-right: 0;
                font-size: 18px;
            }
        }
        .like {
            position: absolute;
            top: 40px;
            right:0;
            @media screen and (max-width: @breakpoint_tb) {
                top:20px ;
                right: 10px;
            }
            @media screen and (max-width: @breakpoint) {
                top:10px ;
            }
            a {
                color:white;
                text-decoration: none;
            }
            .like-baloon {
                position: relative;
                background: #ec5e80;
                font-size: 10px;
                padding: 5px 10px;
                color:white;
                text-decoration: none;
                margin-right: 10px;
                display: inline-block;
                border-radius: 6px;
                &:after {
                    left: 100%;
                    top: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(236, 94, 128, 0);
                    border-left-color: #ec5e80;
                    border-width: 4px;
                    margin-top: -4px;
                }
            }
            .like-icon {
                width: 30px;
                @media screen and (max-width: @breakpoint) {
                    width:24px ;
                }
            }
        }
                            .rate,
                            .heart {
                                display: inline-block;
                                margin-bottom:0;
                            }
                            .rate {
                                border-right: 1px solid black;
                                font-size: 24px;
                                font-weight:600;
                                text-align: left;
                                width: 240px;
                                margin-right: 10px;
                                @media screen and (max-width: @breakpoint) {
                                    font-size: 16px;
                                    width:70%;
                                }
                                &:before {
                                }
                                @media screen and (max-width: @breakpoint) {
                                    &:before {
                                    }
                                }
                            }
                            .heart {
                                font-size: 18px;
                                font-weight:600;
                                &:before {
                                    background:url(/files/user/images/heart.png) center 50% no-repeat;
                                    background-size: 18px;
                                    display: inline-block;
                                    width:18px;
                                    height: 16px;
                                    vertical-align: middle;
                                    content:"";
                                    margin-right: 8px;
                                }
                                @media screen and (max-width: @breakpoint) {
                                    font-size: 16px;
                                    width: auto;
                                    &:before {
                                        background-size: 18px;
                                        margin-right: 4px;
                                        width:18px;
                                        height: 18px;
                                    }
                                }
                            }
    }
    
    .shop-data-bottom {
        padding: 10px 0;
        .shopdata1,.shopdata2 {
            line-height: 1.8;
            font-size: 12px;
        }
        .btn_copy {
            position: absolute;
            top: 0;
            right:0;
            width: auto;
            @media screen and (max-width: @breakpoint) {
                display:none;
            }
        }
    }
    .navigationArea {
        background:#fff75b;
        padding: 15px 0;
        .navigation {
            .flexbox();
        }
        .comment,.photo,.detail, .contribution {
            border-left: 1px solid #101012;
            font-weight: 500;
            width: 25%;
            @media screen and (max-width: @breakpoint_tb) {
                width: 100/3%;
            }
            a {
                display: block;
                text-decoration: none;
                font-size: 16px;
                text-align: center;
                width: 100%;
                color: black;
                width: 100%;
                padding: 1em 0;
                &:before {
                    font-family:@icon;
                    padding-right: 0.5em;
                    font-size: 18px;
                }
                @media screen and (max-width: @breakpoint_tb) {
                    font-size: 12px;
                    padding: 0.5em 0;
                    letter-spacing: -0.05em;
                    &:before {
                        font-size: 14px;
                        padding-right: 0.2em;
                    }
                }
            }
        }
        .comment {
            a:before {
                content: "\e903";
            }
        }
        .photo {
            a:before {
                content: "\e902";
            }
        }
        .detail {
            a:before {
                content: "\e901";
                color: #ffda44;
            }
        }
        .contribution {
            border-right: 1px solid #101012;
            a:before {
                content: "\e904";
                color: #ec5e80;
            }
            @media screen and (max-width: @breakpoint) {
                display: none;
            }
        
        }
    }
    .sliderArea {
        background: #fff75b;
        .mainvisual {
            overflow-x: hidden;
            padding-bottom: 70px;
            position: relative;
            .mainvisual-inner {
                position: relative;
                margin:0 auto;
                max-width: 640px;
                .swiper-container {
                    width: 100%;
                    height: 100%;
                    overflow: visible;
                    z-index: 1;
                }
            }
            img {
                width: 100%;
                vertical-align:bottom;
            }
            .shop-slider {
                &.swiper-container-horizontal>.swiper-pagination-bullets {
                    bottom: -60px;
                    .flexbox();
                    justify-content: center;
                    .swiper-pagination-bullet {
                        width: 100px;
                        height: 50px;
                        border-radius: 0;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        display:block;
                        background-color:transparent;
                        opacity: 0.5;
                    }
                    .swiper-pagination-bullet-active {
                        opacity: 1;
                    }
                }
            }
            .opacity-right {
                width: 100%;
                height: 376px;
                position: absolute;
                bottom: 70px;
                left: calc(~'50% + 320px');
                z-index: 3;
                background:url(/files/user/images/bg.png);
            }
            .opacity-left {
                width: 100%;
                height: 376px;
                position: absolute;
                bottom: 70px;
                right: calc(~'50% + 320px');
                z-index: 3;
                background:url(/files/user/images/bg.png);
            }
            .swiper-button-prev,
            .swiper-button-next {
              fill: #fff;
              stroke: none;
              stroke-width: 0;
              z-index: 10000;
              width: 20px;
              background-color: rbga(0,0,0,0.4;);
              background-image: none;
              text-align: center;
              svg {
                  filter: drop-shadow(0 0 6px rgba(0,0,0,0.7));
              }
            }
            @media screen and (min-width: @breakpoint_tb) {
                .mainvisual-inner {
                    .shop-slider {
                        &.swiper-container-horizontal>.swiper-pagination-bullets {
                            min-width: 960px;
                            left: -160px;
                        }
                    }
                }
            }
            @media screen and (max-width: 640px) {
                padding-bottom: 11%;
                .mainvisual-inner {
                    .shop-slider {
                        &.swiper-container-horizontal>.swiper-pagination-bullets {
                            bottom: -38px;
                            .swiper-pagination-bullet {
                                margin: 0 2px;
                                max-width: 70px;
                                height: 30px;
                            }
                        }
                    }
                }
                .swiper-button-prev,
                .swiper-button-next {
                    width: 18px;
                    margin-top: -22px;
                    svg {
                        width: 20px;
                    }
                }
            }
        }
    }
    .mainvisual-nophoto {
        background: url(/files/user/images/bg.png);
        text-align: center;
    }
    
    .infoArea {
        .inner-in {
            padding: 50px 0;
            @media screen and (max-width: @breakpoint_tb) {
                padding: 30px 0;
            }
        }
        .btn_copy {
            position: absolute;
            top: 45px;
            right:10px;
            width: auto;
            @media screen and (max-width: @breakpoint_tb) {
                top: 25px;
            }
            @media screen and (max-width: @breakpoint) {
                display:none;
            }
        }
        .btn_yellow {
            a {
                font-size: 12px;
                padding: 0.2em 1em;
            }
        }
        .btn_edit,
        .btn_edit-all {
                cursor: pointer;
                background: #f2f1f4;
                color: black;
                text-decoration: none;
                border-radius: 50px;
                text-align: center;
                font-size: 12px;
                padding: 0.2em 1em;
                margin-right:0;
                margin-left: auto;
                width: 9em;
        }
        .btn_conf {
            .linkbtn(#ed797a,white);
            margin-right:0;
            margin-left: auto;
            a {
                padding: 0.2em 1em;
                width: 9em;
                font-size: 12px;
            }
        }
        .ttl {
            text-align: center;
            font-size: 18px;
            margin-bottom: 1em;
            span {
                background: linear-gradient(transparent 60%, #ffff66 60%);
                line-height: 1.3;
                padding: 0 0.3em;
                display:inline-block;
            }
            @media screen and (max-width: @breakpoint_tb) {
                margin-bottom: 0.2em;
            }
        }
        .info {
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 30px;
            tr {
                th,td {
                    padding: 0.8em 0.5em;
                    font-size: 14px;
                    text-align: left;
                    border-bottom: 1px solid black;
                }
                th {
                    font-weight: 500;
                    white-space: nowrap;
                    padding-right: 2em;
                }
                td {
                    word-break: break-all;
                    position: relative;
                    padding-right: 150px;
                    .btn_yellow {
                        display: inline-block;
                        margin-bottom:0;
                    }
                    .btn_edit,
                    .btn_conf  {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                    }
                    .editing,.btn_conf,.none {
                        display:none;
                    }
                    .block {
                        display:block;
                    }
                }
            }
            @media screen and (max-width: @breakpoint) {
                display:block;
                tr {
                    th,td {
                        font-size: 13px;
                        display:block;
                        border:none;
                        padding: 0.6em 0.8em;
                    }
                    th {
                        padding-right: 1em;
                        background:#f6f5f7;
                    }
                    td {
                        padding-bottom: 1em;
                        .btn_conf,
                        .btn_edit {
                            top: -30px;
                        }
                        .btn_edit {
                            background:#a6a6a6;
                            color:white;
                        }
                    }
                }
            }
        }
        .bg_pink,
        .bg_gray {
            border-radius: 10px;
            padding: 40px 10px;
            .btn_pink,
            .btn_darkgray {
                margin:0;
                a,button {
                    display: inline-block;
                    width: 350px;
                }
            }
            @media screen and (max-width: @breakpoint) {
                margin: 10px;
                .btn_pink,
                .btn_darkgray {
                    a,button {
                        font-size: 13px;
                        width: 100%;
                        max-width: 320px;
                        padding-left:0;
                        padding-right:0;
                    }
                }
            }
        }
        .bg_pink {
            display:none;
            background: #fbeaea;
            margin-bottom: 20px;
            .btn_pink {
                button {
                    color:white;
                    background:#ed797a;
                    border:none;
                    font-size: 16px;
                    line-height: 36px;
                    border-radius: 50px;
                }
            }
            @media screen and (max-width: @breakpoint) {
                .btn_pink {
                    button {
                        font-size: 14px;
                    }
                }
            }
        }
        .bg_gray {
            background: #f2f1f4;
        }
    }
}