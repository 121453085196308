.shop-list {
    background:url(/files/user/images/bg-contents.png) no-repeat -120px 99% #f5f5f5;
    @media screen and (max-width: @breakpoint) {
        background-size: 80%;
        background-position: ;
    }
    .inner-in {
        background:url(/files/user/images/bg-contents.png) no-repeat 340px -30px ;
        padding-top: 30px;
        padding-bottom: 30px;
        @media screen and (max-width: @breakpoint) {
            background-position:  45% -50px;
            background-size: 110%;
        }
    }
    .shop-list-ttl {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 0.5em;
        @media screen and (max-width: @breakpoint) {
            font-size: 20px;
        }
    }
    .shop-list-data {
        .shop-data-frame {
            border: 3px solid black;
            border-radius: 10px;
            padding: 15px 25px;
            background: white;
            margin-bottom: 30px;
            position: relative;
            a.shop-link {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: #fff;
                opacity: 0;
                z-index:100;
                &:hover {
                    & + .shop-data .shopname {
                        text-decoration: underline;
                    }
                }
            }
            .shopname {
                margin-bottom: 0.4em;
                padding-right: 30px;
                font-size: 20px;
            }
            .shop-data {
                .flex();
                .shop-meta {
                    background: #f2f1f4;
                    border-radius: 8px;
                    padding: 4px 8px;
                    margin-bottom: 0.5em;
                    .flexbox();
                    .rate {
                        font-size: 22px;
                        width:50%;
                        border-right: 1px solid black;
                        line-height: 1.2;
                        &:before {
                        }
                        @media screen and (max-width: @breakpoint_tb) {
                            font-size: 20px;
                        }
                        @media screen and (max-width: @breakpoint) {
                            font-size: 16px;
                            &:before {
                                width: 70%;
                                margin-right: 3px;
                            }
                        }
                    }
                    .heart {
                        width:18%;
                        font-size: 18px;
                        border-right: 1px solid black;
                                &:before {
                                    background:url(/files/user/images/heart.png) center 50% no-repeat;
                                    background-size: 18px;
                                    display: inline-block;
                                    width:18px;
                                    height: 16px;
                                    vertical-align: middle;
                                    content:"";
                                    margin-right: 8px;
                                }
                                @media screen and (max-width: @breakpoint) {
                                    font-size: 14px;
                                    &:before {
                                        background-size: 14px;
                                        margin-right: 4px;
                                        width:14px;
                                        height: 14px;
                                    }
                                }
                            }
                    .comment-count {
                        position: relative;
                        width:28%;
                        text-align: right;
                        padding-left: 5%;
                        &:before {
                            position: absolute;
                            left:0;
                        }
                        @media screen and (max-width: @breakpoint_tb) {
                            &:before {
                                font-size: 18px;
                            }
                        }
                        @media screen and (max-width: @breakpoint) {
                            font-size: 15px;
                            &:before {
                                font-size: 18px;
                            }
                        }
                    }
                }
                .shop-location,
                .shop-category,
                .shop-price {
                    padding: 0.2em 0 0.2em 24px;
                    font-size: 13px;
                }
                .shop-location {
                    background:url(/files/user/images/icon_map.png) no-repeat left 50% ;
                    background-size: 18px;
                }
                .shop-category {
                    background:url(/files/user/images/icon_eat.png) no-repeat 2px 50% ;
                    background-size: 12px;
                }
                .shop-price {
                    background:url(/files/user/images/icon_price.png) no-repeat left 50% ;
                    background-size: 20px;
                }
                .mapLink {
                    display: inline-block;
                    border:2px solid black;
                    padding: 0 1em;
                    border-radius: 5px;
                    color:black;
                    text-decoration: none;
                    margin-left: 1em;
                    z-index:200;
                    position:relative;
                    &:hover {
                        background: #ed797a;
                        color:white;
                        border:2px solid #ed797a;
                    }
                }
            }
            .photo1,.photo2 {
                margin-left: 12px;
            }
            .like {
                width: 31px;
                display: block;
                top:-3px;
                right: 10px;
                position: absolute;
                z-index:100;
                a:hover {
                    img {
                        opacity:1;
                    }
                }
                img {
                    width: 100%;
                }
            }
            .comment {
                border-top: 1px dotted black;
                padding-top: 15px;
                position: relative;
                margin-top: 5px;
                figure {
                    position: absolute;
                    width: 32px;
                    img {
                        width:100%;
                    }
                }
                .comment-txt,
                .comment-profile {
                    margin-left: 40px;
                }
                .comment-profile {
                    font-size: 12px;
                    margin-bottom:0;
                }
            }
            @media screen and (min-width: @breakpoint_tb) {
                .flexbox {
                    .flexbox();
                }
                .shop-data {
                    max-width: 470px;
                    margin-right: 10px;
                }
            }
            @media screen and (max-width: @breakpoint_tb) {
                padding: 15px;
                .shopname {
                    font-size: 18px;
                }
                .photo1,.photo2 {
                    width: 47%;
                    margin:1%;
                    display: inline-block;
                    text-align: center;
                }
                .like {
                    width: 25px;
                }
            }
        }
    }
    
}