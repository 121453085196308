@import "../_less/mixin.less";
@import "../_less/variables.less";
/* common　*/
@font-face {
    font-family: 'icon-shokutujin';
    src:  url('/files/user/fonts/icon-shokutujin.eot?kmvghd');
    src:  url('/files/user/fonts/icon-shokutujin.eot?kmvghd#iefix') format('embedded-opentype'),
    url('/files/user/fonts/icon-shokutujin.ttf?kmvghd') format('truetype'),
    url('/files/user/fonts/icon-shokutujin.woff?kmvghd') format('woff'),
    url('/files/user/fonts/icon-shokutujin.svg?kmvghd#icon-shokutujin') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon-shokutujin' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
    position: relative;
  @media screen and (max-width: @breakpoint_tb) {
      font-size:62.5% ;
  }
}
body {
    position: relative;
    font-family: @fontNormal;
    font-weight: normal;
    color: #333333;
    line-height: 1.6;
    letter-spacing: 0;
    margin: 0 auto;
    background: white;
    -webkit-text-size-adjust: 100%;
    min-width: @site_width;
    @media screen and (max-width: @breakpoint_tb) {
      font-size:1.4rem ;
      min-width: @breakpoint_min;
    }
    @media screen and (min-width: @breakpoint_tb) {
      font-size:14px ;
    }
}
    .wrapper {
        overflow: hidden;
        @media screen and (max-width: @site_width) {
            overflow: hidden;
        }
        .inner-in {
            margin: 0 auto;
            position: relative;
            width: @breakpoint_tb;
            @media screen and (max-width: @breakpoint_tb) {
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .inner-out {
            margin: 0 auto;
            position: relative;
            width: @site_content_width;
            @media screen and (max-width: @breakpoint_tb) {
                width: 100%;
            }
        }
        @media screen and (max-width: @breakpoint) {
            img {
                width:auto;
                max-width:100%;
            }
        }
    }
        .sp {
            display:none;
            @media screen and (max-width: @breakpoint) {
                display:block;
            }
        }
        .tb {
            display:none;
            @media screen and (max-width: @breakpoint_tb) {
                display:inline;
            }
        }
        .pc {
            display:inline;
            @media screen and (max-width: @breakpoint_tb) {
                display:none !important;
            }
        }
        .pc_tb {
            display:inline;
            @media screen and (max-width: @breakpoint) {
                display:none !important;
            }
        }
        a:hover img {
            @media screen and (min-width: @breakpoint_tb) {
                opacity: .7;
                -webkit-opacity: .7;
                -moz-opacity: .7;
            }
        }
        .mincho {
            font-family: @fontMincho;
        }
// btn setting
.btn_copy {
    background: white;
    border-radius: 8px;
    border: 2px solid black;
    cursor: pointer;
    font-size: 14px;
    a {
        color: black;
        padding: 0.3em 1em;
        text-decoration: none;
        display: block;
        &:before {
          content: "\e900";
          font-family:@icon;
        }
    }
    @media screen and (max-width: @breakpoint_tb) {
        text-align: center;
        margin-top: 0.5em;
        a {
            padding: 0.3em 1em;
        }
    }
}
.btn_more {
    .linkbtn();
}
.btn_gray {
    .linkbtn(#f2f1f4,black);
}
.btn_yellow {
    .linkbtn(#fff75b,black);
}
.btn_darkgray {
    .linkbtn(#a6a6a6,white);
}
.btn_pink {
    .linkbtn(#ed797a,white);
}

/* icon */
.icon:before {font-family:@icon;}
.icon-01:before { content: "\e900";}// copy
.icon-02:before { content: "\e903";} // comment
.icon-03:before { content: "\e902";} // camera
.icon-04:before { content: "\e901"; color: #ffda44;} // info
.icon-05:before { content: "\e904";} // edit
.icon-06:before { content: "\e905";} // soat
.icon-07:before { content: "\e906";} // search
.icon-08:before { content: "\e90a";} // arrow-bottom
.icon-09:before { content: "\e909";} // arrow-top
.icon-10:before { content: "\e908";} // arrow-left
.icon-11:before { content: "\e907";}// arrow-right

/* grid */
.row {
  margin-left: -15px;
  margin-right: -15px;
  .clearfix();
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6{
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-6 {
  width: 100/6*6%;
}
.col-5 {
  width: 100/6*5%;
}
.col-4 {
  width: 100/6*4%;
}
.col-3 {
  width: 100/6*3%;
}
.col-2 {
  width: 100/6*2%;
}
.col-1 {
  width: 100/6*1%;
}
@media screen and (max-width: @breakpoint) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6{
        float: none;
        width: 100%;
    }
}


/* breadcrumb　*/
    .breadcrumb {
        background: #fff75b;
        padding: 1em 0;
        ul {
            li {
                display: inline-block;
                font-size: 12px;
                a {
                    color:black;
                    text-decoration: none;
                    padding-right: 0.5em;
                }
                &:not(:last-child) {
                    a {
                        &:after {
                            content:url(/files/user/images/arrow-left-gr.svg);
                            padding-left: 1em;
                        }
                    }
                }
            }
        }
    }

/* registration */
.registration {
    position: fixed;
    top: 5%;
    right: 5%;
    z-index: 200;
    @media screen and (max-width: @breakpoint_tb) {
        width: 18%;
        position: absolute;
        top: 85%;
        right: 3%;
    }
}
@media screen and (max-width: @breakpoint_tb) {
    .registration-sp {
        -webkit-transition: all .3s;
        transition: all .3s;
        top:-30px;
        position: fixed;
        opacity:0;
    }
    .registration-sp.scrolled {
        opacity:1;
        top:0;
        background: #ec5e80;
        color:white;
        padding: 0;
        width: 100%;
        line-height: 35px;
        z-index:2000;
        a {
            color:white;
            text-decoration: none;
            display: block;
            text-align: center;
            .icon:before {
                font-family:@icon;
                content: "\e904";
                font-size: 15px;
                margin-right: 5px;
            }
        }
    }
}

/* header */

.header {
    background: url(/files/user/images/bg-header.png) top center;
    height: 77px;
    padding: 13px 0;
    @media screen and (max-width: @breakpoint) {
        height: 66px;
    }
    .logo {
        float: left;
        @media screen and (max-width: @breakpoint_tb) {
            width: 70%;
        }
        a {
            text-decoration: none;
        }
    }
    .icon .icon-img {
        border-radius: 100%;
    }
    .profile {
        background: #fff;
        border-radius: 30px;
        float: right;
        margin: 8px 28px 8px 50px;
        padding: 0 20px 0 50px;
        position: relative;
        @media screen and (max-width: @breakpoint) {
            padding-left: 40px;
            padding-right: 10px;
        }
        .personal-txt {
            line-height: 40px;
        }
        .icon {
            left: 0;
            position: absolute;
            top: 0;
            .icon-img {
                width: 41px;
            }
            .badge {
                left: 27px;
                position: absolute;
                top: -3px;
            }
        }
        @media screen and (max-width: @breakpoint_tb) {
            background:none;
            margin:0;
            float:none;
            position:absolute;
            right: 70px;
            .personal-txt {
                display: none;
            }
            .icon {
                .icon-img {
                }
            }
        }
        @media screen and (max-width: @breakpoint) {
            right: 60px;
            .icon {
                .icon-img {
                    width: 30px;
                }
                .badge {
                    width: 12px;
                    left: 23px;
                    top: -2px;
                }
            }
        }
    }
    .clip {
        background: #fff;
        border-radius: 30px;
        float: right;
        padding: 0 11px 0 52px;
        margin: 8px 0;
        position: relative;
        .clip_num {
            line-height: 40px;
        }
        .heart {
            left: 0;
            position: absolute;
            top: 0;
            width: 41px;
        }
        @media screen and (max-width: @breakpoint_tb) {
            margin:0 0 0 0;
        }
        @media screen and (max-width: @breakpoint) {
            padding: 0 11px 0 32px;
            .heart {
                width: 30px;
            }
            .clip_num {
                line-height: 30px;
            }
        }
    }
    .notice {
        position: absolute;
        top: 46px;
        left: -88px;
        z-index: 1000;
        padding: 28px 20px 18px;
        width: 223px;
        height: 63px;
        background: url(/files/user/images/bg-notice.png) no-repeat;
        font-size: 14px;
        a {
            float: right;
            margin-right: 9px;
        }
        &:hover {
            cursor: pointer;
        }
        @media screen and (max-width: @breakpoint) {
            font-size: 13px;
            width: 190px;
            background-size: 100%;
            padding:22px 11px 13px;
            top: 32px;
            left: -78px;
        }
    }
    &.topPage {
        .logo {
            display: none;
        }
    }
    &.subPage {
        .logo-img {
            width: 165px;
            @media screen and (max-width: @breakpoint) {
                width: 40%;
                max-width: 165px;
            }
        }
        .catch-img {
            width: 226px;
            @media screen and (max-width: @breakpoint) {
                width: 50%;
                max-width: 226px;
            }
        }
    }
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}


/* search */
.search {
    font-size: 14px;
    .linkarea {
        background: url(/files/user/images/bg-linkarea.png) #fff75b top center no-repeat;
        background-size: 100%;
        @media screen and (max-width: @breakpoint_tb) {
            background-size: 150%;
        }
        .inner-in {
            height: 80px;
        }
        .tab {
            position: absolute;
            bottom: 0;
            li {
                background: #c9c9c9;
                border-radius: 10px 10px 0 0;
                float: left;
                height: 46px;
                margin-right: 15px;
                position: relative;
                width: 220px;
                &.active {
                    background: #fff;
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
                .comingsoon {
                    top: -50px;
                }
                &:not(.active) {
                    .tabmenu-img {
                        opacity:0.5;
                    }
                }
            }
        }
    }
    .inner-in {
        position: relative;
    }
    .arrow {
        text-align: center;
        margin-bottom: 40px;
        @media screen and (max-width: @breakpoint) {
            margin-bottom: 3%;
            img {
                width:30px;
            }
        }
    }
    .ttl {
        text-align: center;
        line-height: 1;
        @media screen and (max-width: @breakpoint) {
            img {
                width:60%;
                max-width: 300px;
            }
        }
    }
    .dropdown {
        .clearfix();
        float: left;
        border-radius: 10px;
        margin-bottom: 20px;
        .dotBg(url(/files/user/images/bg-dot-yellow.png));
    }
    .des_search_menu {
        float: left;
        width: 233px;
        background-color: #fff;
        border: 2.5px solid;
        &:hover {
            background-color: #ddd;
            cursor: pointer;
        }
        &:after {
            font-family:@icon;
            content: "\e90a";
            position: absolute;
            top: 50%;
            margin-top: -10px;
            right: 13px;
            color: #969696;
            font-size: 15px;
            @media screen and (max-width: @breakpoint) {
                content: "\e908";
            }
        }
        &.active:after {
            content: "\e909";
            @media screen and (max-width: @breakpoint) {
                content: "\e908";
            }
        }
    }
    .des_topmenu_sel {
        padding: 25px 20px;
        line-height: 16px;
        @media screen and (max-width: @breakpoint) {
            padding: 20px 20px;
        }
    }
    #topMenuArea {
        border-right: 1px solid #000;
        border-radius: 10px 0 0 10px;
    }
    #topMenuGenr {
        border-left: none;
        border-right: 1px solid #000;
    }
    #moresrch {
        border-left: none;
        border-radius: 0 10px 10px 0;
    }
    .des_disp_none {
        display: none;
    }
    .des_disp_block {
        display: block;
    }
    label:hover {
        cursor: pointer;
    }
    label.checkBoxlabel {
        padding: 10px 10px 10px 34px;
        position: relative;
        display: inline-block;
        @media screen and (max-width: @breakpoint) {
            padding: 8px 10px 8px 30px;
        }
    }
    label.checkBoxlabel::before {
        content: "";
        display: block;
        background: url(/files/user/images/check-off.png) no-repeat left;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
        top: 9px;
        @media screen and (max-width: @breakpoint) {
            width: 20px;
            height: 20px;
            background-size: 100%;
        }
    }
    input:checked + label.checkBoxlabel::after {
        content: "";
        display: block;
        background: url(/files/user/images/check-on.png) no-repeat left;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
        top: 9px;
        @media screen and (max-width: @breakpoint) {
            width: 20px;
            height: 20px;
            background-size: 100%;
        }
    }
    .bottom_btn {
        text-align:right;
        margin: 15px 0;
        .des_selClear {
            padding: 10px 60px;
            background: #c8c8c8;
            border-radius: 30px;
            color: #fff;
            line-height: 1;
            display: inline-block;
        }
        .des_selClose {
            padding: 10px 60px;
            background: #f2f1f4;
            border-radius: 30px;
            line-height: 1;
            display: inline-block;
        }
    }
    .arw {
        position: absolute;
        background: url(/files/user/images/arrow-02.png) no-repeat;
        width: 14px;
        height: 9px;
        top: 19px;
        right: 15px;
        &.active {
            background: url(/files/user/images/arrow-03.png) no-repeat;
        }
    }

    .des_topmenu_srchBtnbox {
        width: 24%;
        float: right;
        text-align: center;
        background: #ed797a;
        border-radius: 10px;
        input {
            padding: 25px;
            background: none;
            border: none;
            color: #fff;
            display: block;
            width: 100%;
        }
    }
    .place {
        text-align: center;
        background: #2eb6aa;
        border-radius: 10px;
        a {
            color: #fff;
            text-decoration: none;
            padding: 20px;
            display: block;
            @media screen and (max-width: @breakpoint) {
                padding: 10px 20px;
            }
            &:before {
                content: "";
                display: inline-block;
                width:19px;
                height:19px;
                vertical-align: baseline;
                padding-right: 10px;
                line-height: 19px;
                background: url(/files/user/images/icon_mapsearch.png) center top no-repeat;
            }
        }
    }
    .clear {
        text-align: center;
        background: #7d7d7d;
        border-radius: 10px;
        a {
            color: #fff;
            text-decoration: none;
            padding:  20px;
            display: block;
            line-height: 20px;
        }
    }
    .keyword {
        #topMenuKw {
            background-color: #fff;
            border: 2.5px solid;
            border-radius: 10px;
            overflow: hidden;
            .des_topmenu_sel {
                padding: 0;
            }
            .des_topmenu_sel:hover {
                background-color: #ddd;
            }
            input {
                border: none;
                font-size: 12px;
                width: 100%;
                padding: 25px 20px 25px 38px;
                background: url(/files/user/images/loupe.png) 13px 50% no-repeat;
                @media screen and (max-width: @breakpoint) {
                    padding: 15px 20px 15px 38px;
                }
            }
            input:focus {
                outline: none;
            }
        }
    }
    .btnArea {
        margin: 20px 0;
        .flexbox();
        .place,
        .keyword,
        .des_topmenu_srchBtnbox {
            float:none;
            width: 32%;
        }
    }
    .des_flat_option {
    }
    #areaListSelBox {
        width: 960px;
        background: #fff;
        border: 2.5px solid;
        z-index: 20;
        @media screen and (max-width: @breakpoint) {
            width: 100%;
        }
        .clearfix {
            clear: both;
            display: inline-block;
            width: 100%;
            padding-bottom: 13px;
            border-bottom: 1px solid #e4e4e4;
        }
        .des_areaAll {
            padding: 10px 0;
            float:left;
        }
        #area12ListSelBox {
            display: block;
            position: absolute;
            top: 48px;
            z-index: 3;
            background: #fff;
            width: 215px;
            border: 2.5px solid;
            #area12List {
                max-height: 300px;
                overflow-x: hidden;
                .evt_clickAreaMin12 {
                    display: block;
                    padding: 16px;
                    &:hover {
                        background: #ddd;
                    }
                }
            }
        }
        #area12SelBox {
            float: left;
            border: 2.5px solid;
            border-radius: 10px;
            position: relative;
            margin-right: 30px;
            .des_area_select {
                width: 210px;
                float: left;
                display: inline-block;
                padding: 16px 0 14px;
                text-align: center;
            }
        }
        .areaListSelBox_inner {
        }
        #area3ListSelBox {
            display: inline-block;
            border-bottom: 1px solid #e4e4e4;
            width: 100%;
            .des_box_area3 {
                display: flex;
                justify-content: flex-start;
                -webkit-flex-wrap:wrap;
                flex-wrap:wrap;
                .des_area3 {
                    width: 100/3%;
                    padding: 0;
                    .des_box_area4 {
                        padding-left: 20px;
                        margin-bottom: 10px;
                        .des_area4 {
                            width: 100%;
                            padding: 0;
                            display: inline-block;
                            label.checkBoxlabel {
                                padding: 4px 0 4px 34px;
                            }
                            label.checkBoxlabel::before {
                                top: 4px;
                            }
                            input:checked + label.checkBoxlabel::after {
                                top: 4px;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: @breakpoint) {
                .des_box_area3 {
                    .des_area3 {
                        width: 100/2%;
                    }
                }
            }
        }
    }
    #genrListSelBox {
        width: 100%;
        background: #fff;
        border: 2.5px solid;
        z-index: 20;
        #genrList {
            .des_genr_list {
                border-bottom: 1px solid #e4e4e4;
                width: 100%;
                padding-bottom: 10px;
                li {
                    display: inline-block;
                    margin-right: 10px;
                }
                li:first-child {
                    width: 100%;
                }
            }
        }
    }
    #subsrch_more {
        width: 100%;
        background: #fff;
        border: 2.5px solid;
        z-index: 20;
        input {
        }
        .des_itemRow_wrap {
        }
        .des_itemRow {
            font-size: 13px;
            padding: 10px 0;
            border-bottom: 1px dotted #ccc;
            background-color: #fff;
            width: 100%;
            display: flex;
            .des_itemTitle {
                width: 120px;
                vertical-align: top;
                color: #000;
                float: left;
                text-align: left;
                font-weight: 400;
                font-size: 14px;
                padding-left: 3px;
                padding-top: 10px;
                margin-right: 20px;
            }
            .des_item {
                float: left;
                line-height: 22px;
                width: 900px;
            }
            .des_box_drink4 {
                width: 22%;
                margin-right: 2%;
                float: left;
                .boxdrink {
                    padding-left: 20px;
                    label.checkBoxlabel {
                        padding: 4px 0 4px 34px;
                    }
                    label.checkBoxlabel::before {
                        top: 4px;
                    }
                    input:checked + label.checkBoxlabel::after {
                        top: 4px;
                    }
                }
            }
            @media screen and (max-width: @breakpoint) {
                border:none;
                display: block;
                .clearfix();
                .des_itemTitle {
                    width: 100%;
                    float: none;
                    background:#f2f1f6;
                    text-align: center;
                    padding: 5px 0;
                }
                .des_item {
                    float: none;
                    width :100%;
                }
                .des_box_drink4 {
                    width: 100%;
                    float: none;
                    margin:0;
                }
            }
        }
    }
    .tc {
        float: left;
        line-height: 30px;
        margin-right: 10px;
        padding: 9px;
        @media screen and (max-width: @breakpoint) {
            float:none;
            display:block;
            line-height: 1.4;
        }
    }
    .des_sc_cont {
        float: left;
        display: flex;
    }
    #pplwListSel,
    #pphiListSel {
        width: 140px;
        display: flex;
        position: relative;
        border: 1px solid #000;
    }
    .des_pplwListSel,
    .des_pphiListSel {
        padding: 12px 42px 12px 15px;
        @media screen and (max-width: @breakpoint) {
        }
    }
    #pplwListSelBox,
    #pphiListSelBox {
        display: block;
        position: absolute;
        margin-top: 47px;
        background: #fff;
        width: 140px;
        z-index: 30;
    }
    #pplwListSelBox li:hover,
    #pphiListSelBox li:hover {
        background: #ddd;
    }
    #pplwList,
    #pphiList {
        border: 1px solid;
        max-height: 300px;
        overflow-x: hidden;
    }
    .evt_click_param {
        padding: 8px 15px;
        display: block;
    }
    .des_price_center {
        padding: 9px 10px;
        line-height: 30px;
    }
    @media screen and (max-width: @breakpoint_tb) {
        .linkarea {
            .inner-in {
                .tab {
                    width: 100%;
                    li {
                        width: 30%;
                        margin-right: 3%;
                        height: 36px;
                        .tabmenu-img {
                            width: 70%;
                            max-width: 110px;
                        }
                        .comingsoon {
                            width: 40%;
                            max-width: 50px;
                        }
                    }
                }
            }
        }
    }
    #areaListSelBox,#genrListSelBox,#subsrch_more {
        display:none;
        padding: 20px 20px ;
        position: absolute;
        top: 66px;
        input:not(.lg_srchSubmit) {
            display: none;
        }
    }
    @media screen and (max-width: @breakpoint) {
        #areaListSelBox,#genrListSelBox,#subsrch_more {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: auto;
            z-index:9999;
            border:none;
            padding:0;
            .areaListSelBox_inner,
            #genrList,
            .des_itemRow_wrap {
                padding: 10px 10px 70px 10px;
            }
        }
        .search_ttl {
            background: #fdf676;
            padding: 15px 60px 15px 45px;
            font-size: 15px;
        }
        .bottom_btn {
            position: initial;
            text-align: center;
            margin:10px 0 0 0;
            .des_selClose {
                display: block;
                position: absolute;
                margin: 0;
                padding: 0;
                top: -40px;
                left: 10px;
                text-indent:100%;
                background:none;
                border-radius:0;
                height: 30px;
                width: 30px;
                white-space:nowrap;
                overflow:hidden;
            }
            .des_selClose:after {
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 32px;
                height: 3px;
                margin-top: -1px;
                background: #5eb5a7;
                transform: rotate(45deg);
            }
            .des_selClose:before {
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 32px;
                height: 3px;
                margin-top: -1px;
                background: #5eb5a7;
                transform: rotate(-45deg);
            }
            .des_selClear {
                font-size: 13px;
                padding: 8px 30px;
            }
        }
        .des_srchBtnbox {
            text-align: center;
            background: #ed797a;
            color: white;
            padding: 20px;
            position: fixed;
            bottom: 0;
            width: 100%;
            input {
                display:block;
                color: white;
                margin: 0 auto;
                background: none;
            }
        }
    }
}

.search.topPage {
    .mainsrch {
        height:220px;
    }
    .dropdown {
        width: 100%;
        .des_search_menu {
            width: 100/3%;
        }
    }
    
    @media screen and (max-width: @breakpoint) {
        .btnArea {
            display: block;
            margin-bottom:0;
            .des_topmenu_srchBtnbox {
                display:none;
            }
        }
        .btnArea .place, .btnArea .keyword, .btnArea .des_topmenu_srchBtnbox {
            width: 100%;
            float:none;
            margin-bottom:10px;
        }
        .mainsrch {
            height:auto;
            border:3px solid black;
            border-radius: 10px;
            margin: 10px;
        }
        .dropdown {
            float:none;
            .des_search_menu {
                width: 100%;
                float:none;
            }
            &:after {
                background:none;
                
            }
        }
        #topMenuArea,#topMenuGenr,#moresrch {
            border:none;
            border-bottom: 1px solid black;
            border-radius:0;
        }
    }
}
.search.subPage {
    .linkarea {
        .inner-in {
            height:80px;
        }
    }
    .mainsrch {
        .inner-in {
            padding: 30px 0 0;
            margin-bottom: 20px;
            .clearfix();
        }
        &.bg {
            background: url(/files/user/images/bg-linkarea.png) #fff75b top center no-repeat;
            background-size: 100%;
            @media screen and (max-width: @breakpoint_tb) {
                background-size: 180%;
            }
            .dropdown {
                margin:0;
            }
        }
    }
    @media screen and (max-width: @breakpoint_tb) {
        .linkarea {
            height: 60px;
            .inner-in {
                height: 60px;
                .tab {
                }
            }
        }
        .mainsrch {
            height: auto;
            .inner-in {
                padding: 20px 10px 10px;
            }
        }
        .des_search_menu {
            width: 100/3%;
            background-size: 10px;
            background-position: 90% 50%;
        }
        .dropdown {
            float:none;
            width :100%;
            margin-right:0;
            .des_topmenu_sel {
                padding: 0.8em 0.5em;
                span {
                    font-size: 11px;
                }
            }
        }
        .des_topmenu_srchBtnbox {
            display:none;
        }
    }
}
/* search-result-head */
.search-result-head {
    padding-bottom: 20px;
    margin-top: 20px;
    .search-tags {
        border-bottom: 1px solid black;
        padding-bottom: 1em;
        margin-bottom: 1em;
        .search-tags-ttl {
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            margin-right: 0.5em;
            &:before {
                content: "\e906";
                font-family:@icon;
                font-size: 18px;
                vertical-align: middle;
                padding-right: 0.3em;
            }
        }
        .taglist {
            display: inline-block;
            .tag {
                display: inline-block;
                text-align: center;
                background :#eeeeee;
                line-height: 1;
                padding: 0.5em 1em;
                min-width: 5em;
                border-radius: 5px;
                font-size: 14px;
                margin-right: 0.3em;
            }
        }
    }
    .soatArea {
        .alignitem-center();
        font-size: 14px;
        .result-count {
            margin-right: auto;
        }
        .sort-ttl {
            position: relative;
            &:before {
                content: "\e905";
                font-family:@icon;
            }
        }
        @media screen and (min-width: @breakpoint) {
            .flexbox();
        }
        @media screen and (max-width: @breakpoint) {
            .result-count {
                margin-bottom: 5px;
            }
            .sort-ttl {
                display:inline-block;
            }
            .select-wrap {
                vertical-align: middle;
            }
        }
    }
}

/* pagenation */
.pagenation {
    margin: 40px 0;
    padding: 20px;
    background:white;
    border-radius: 10px;
    .flexbox();
    justify-content :center;
    .prev,.next,.inactive,.active,.more {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3em;
        height: 3em;
        font-size: 14px;
        margin: 5px;
        a {
            display: block;
            width: 100%;
            height: 3em;
            color: black;
            background:#f2f1f4;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            &:hover {
                background:#fff75b;
            }
        }
    }
    .active {
        border-radius: 10px;
        border: 3px solid black;
        background: white;
    }
    .prev {
        width: 6em;
        margin-right: 20px;
        a:before {
            content: "\e907";
            font-family:@icon;
            font-size: 12px;
            padding-right: 5px;
        }
    }
    .next {
        width: 6em;
        margin-left: 20px;
        a:after {
            content: "\e908";
            font-family:@icon;
            font-size: 12px;
            padding-left: 5px;
        }
    }
    .more {
        margin: 0;
    }
    @media screen and (max-width: @breakpoint) {
        margin: 20px 0;
        padding: 10px;
        .prev,.next,.inactive,.active,.more {
            width: 2.5em;
            height: 2.5em;
            font-size: 13px;
            margin: 3px;
            a {
                border-radius: 5px;
                height: 2.5em;
            }
        }
        .active {
            border-radius: 8px;
            border: 2px solid black;
        }
        .prev {
            width: 2.5em;
            margin-right: 5px;
            span {
                display:none;
            }
        }
        .next {
            width: 2.5em;
            margin-left: 5px;
            span {
                display:none;
            }
        }
        .more {
            margin: 0;
            width: 1.5em;
        }
    }
}
/* sort */
.select-wrap{
position:relative;
overflow:hidden;
display:inline-block;
min-width:192px;
min-width:12em;
background-color:#ffffff;
border:2px solid #000000;
color:#333;
border-radius: 8px;
}

.select-wrap select{
-webkit-appearance:none;
-moz-appearance:none;
appearance:none;
position:relative;
z-index:2;
display:block;
width:200%;/* fallback non calc support */
width:-webkit-calc(~'100% + 5em');
margin:0;
padding:11px 35px 11px 11px;
padding:.4rem 2.1875rem .4rem .6875rem;
background:transparent;
border:0;
outline:none;
font-size:14px;
line-height:1.5;
}

.entypo-down-open-mini:before{
content: "\e90a";
font-family:@icon;
position:absolute;
z-index:1;
top:50%;
right:12px;
right:.75rem;
margin-top:-8px;
margin-top:-.5rem;
font-size:14px;
font-size:1rem;
line-height:1;
color:#333;
}
/* shop data */

                            .rate {
                                &:before {
                                    display: inline-block;
                                    width:60%;
                                    max-width: 150px;
                                    min-width: 90px;
                                    height: 32px;
                                    vertical-align: middle;
                                    content:"";
                                    margin-right: 10px;
                                    background-position: center 50%;
                                    background-repeat:no-repeat;
                                    background-size: 100%;
                                }
                                &.rate00:before {
                                    background-image:url(/files/user/images/rate00.png) ;
                                }
                                &.rate05:before {
                                    background:url(/files/user/images/rate05.png) center 50% no-repeat;
                                }
                                &.rate10:before {
                                    background:url(/files/user/images/rate10.png) center 50% no-repeat;
                                }
                                &.rate15:before {
                                    background:url(/files/user/images/rate15.png) center 50% no-repeat;
                                }
                                &.rate20:before {
                                    background:url(/files/user/images/rate20.png) center 50% no-repeat;
                                }
                                &.rate25:before {
                                    background:url(/files/user/images/rate25.png) center 50% no-repeat;
                                }
                                &.rate30:before {
                                    background:url(/files/user/images/rate30.png) center 50% no-repeat;
                                }
                                &.rate35:before {
                                    background:url(/files/user/images/rate35.png) center 50% no-repeat;
                                }
                                &.rate40:before {
                                    background:url(/files/user/images/rate40.png) center 50% no-repeat;
                                }
                                &.rate45:before {
                                    background-image:url(/files/user/images/rate45.png);
                                }
                                &.rate50:before {
                                    background:url(/files/user/images/rate50.png) center 50% no-repeat;
                                }
                                @media screen and (max-width: @breakpoint) {
                                    &:before {
                                        width:60%;
                                        height: 26px;
                                        
                                    }
                                }
                                @media screen and (max-width: @breakpoint) {
                                    &:before {
                                        margin-right: 5px;
                                    }
                                }
                            }
                            .comment-count {
                                font-size: 18px;
                                span {
                                    padding-left: 0.4em;
                                }
                                &:before {
                                    content: "\e903";
                                    font-family:@icon;
                                    font-size: 22px;
                                    vertical-align: middle;
                                }
                            }


/* hot */
.hot {
    background: url(/files/user/images/bg-hot.png) #fff75b top center no-repeat;
    //height: 447px;
    position: relative;
    @media screen and (max-width: @breakpoint_tb) {
        background-size: 110%;
    }
    .inner-in {
        padding-top: 40px;
        padding-bottom: 40px;
        position: relative;
        @media screen and (max-width: @breakpoint_tb) {
            margin:0;
            padding-top: 4%;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .ttl {
        text-align: center;
        margin-bottom: 30px;
        @media screen and (max-width: @breakpoint) {
            width: 326/254*60%; 
            margin: 0 auto 20px;
        }
    }
}
.hot .swiper-container3 {
    width: @breakpoint_tb;
    margin-left: -30px;
    position: relative;
  @media screen and (max-width: @breakpoint_tb) {
      width: 100%;
      margin:0;
  }
}
.hot .swiper-slide {
    overflow: hidden;
    border: 2.5px solid #000;
    background: #fff;
    border-radius: 15px;
  //height: 245px;
  //transition: all 0.5s ease-in-out;
    .image {
        @media screen and (max-width: @breakpoint) {
            img {
                width: 100%;
            }
        }
    }
}
.hot .swiper-slide-active {
    margin-left: 30px;
  //transition: all 0.5s ease-in-out;
  @media screen and (max-width: @breakpoint_tb) {
      margin-left:0;
  }
}
.hot .swiper-slide-active + div + div + div + div {
    margin-left: 30px;
  @media screen and (max-width: @breakpoint_tb) {
      margin-left: 0;
  }
}
.hot .swiper-slide-prev {
}
.hot .swiper-button-prev {
    background-image: url(/files/user/images/arrow-left-02.png);
    background-size: auto;
    top: 50%;
    left: 0;
    margin-top: -22px;
  @media screen and (max-width: @breakpoint_tb) {
      left:-30px;
  }
}
.hot .swiper-button-next {
    background-image: url(/files/user/images/arrow-right-02.png);
    background-size: auto;
    top: 50%;
    right: -60px;
    margin-top: -22px;
  @media screen and (max-width: @breakpoint_tb) {
      right:-30px;
  }
}
.hot .tag {
    padding: 10px 15px 0 15px;
    min-height: 3.3em;
    font-size: 14px;
    line-height: 1.3;
    @media screen and (max-width: @breakpoint) {
        font-size: 13px;
        padding: 10px 10px 0 10px;
    }
}
.hot .shopname {
    padding: 0 15px;
    margin: 0;
    height: 67px;
    line-height: 1.3;
    text-align: left;
    font-size: 17px;
    font-weight: 600;
    @media screen and (max-width: @breakpoint) {
        font-size: 15px;
        padding: 0 10px;
    }
}
.hot a {
    color: #000;
    text-decoration: none;
}
.hot .opacity-right {
    width: 100%;
    height: 280px;
    position: absolute;
    bottom: 30px;
    left: calc( ~'50% + 520px' );
    z-index: 2;
    background: rgba(255, 247, 91, 0.6);
    @media screen and (max-width: @breakpoint_tb) {
        left: calc( ~'100% - 20px' );
        height: 100%;
        bottom:0;
    }
}
.hot .opacity-left {
    width: 100%;
    height: 280px;;
    position: absolute;
    bottom: 30px;
    right: calc( ~'50% + 520px' );
    z-index: 2;
    background: rgba(255, 247, 91, 0.6);
      @media screen and (max-width: @breakpoint_tb) {
        right: calc( ~'100% - 20px' );
        height: 100%;
        bottom:0;
    }
}

/* word */

.word {
        .ttl {
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: @breakpoint) {
                margin-bottom: 20px;
                img {
                    width: 60%;
                }
            }
        }
}
.word .inner-in {
    padding: 60px 15px 40px;
}
.word ul {
    display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  @media screen and (max-width: @breakpoint) {
      grid-template-columns: 1fr 1fr;
  }
}
.word li {
    text-align: center;
    border-radius: 15px;
    padding: 0;
  .dotBg(url(/files/user/images/bg-dot-pink.png));
}
.word li a {
    padding: 15px 0;
    display: inline-block;
    width: 100%;
    color: #000;
    text-decoration: none;
    font-weight: bold;
    border: 2.5px solid #000;
    border-radius: 10px;
    background: #fff;
}

/* photo */
    .photoArea {
        background: #f2f1f4;
        .inner-in {
            padding: 50px 0;
            background:url(/files/user/images/bg-contents.png) no-repeat 70px -30px ;
            @media screen and (max-width: @breakpoint) {
                background-size: 120%;
                background-position:20px -30px;
            }
        }
        .ttl {
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: @breakpoint) {
                img {
                    width: 176/254*60%;
                }
            }
        }
        .photolist {
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            justify-items: stretch;
            align-items: stretch;
            .thumb {
                width: 143px;
                height: 143px;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            @media screen and (max-width: @breakpoint_tb) {
                margin: 0 12px 10px;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                .thumb {
                    width: 22vw;
                    height: 22vw;
                }
            }
            @media screen and (max-width: @breakpoint) {
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                grid-column-gap: 10px;
                grid-row-gap: 10px;
                .thumb {
                    width: 21.5vw;
                    height: 21.5vw;
                }
            }
        }
    }

/* comment */
    .commentArea {
        .inner-in {
            padding: 50px 0;
            background:url(/files/user/images/bg-contents.png) no-repeat 40px -30px ;
            @media screen and (max-width: @breakpoint) {
                background-size: 120%;
                background-position:20px -10px ;
            }
        }
        .ttl {
            text-align: center;
            margin-bottom: 50px;
            @media screen and (max-width: @breakpoint) {
                margin-bottom: 30px;
                img {
                    width: 60%;
                }
            }
        }
        .comments-list {
            @media screen and (max-width: @breakpoint_tb) {
                margin: 0 15px 20px;
            }
            .comment {
                margin-bottom: 30px;
                position: relative;
                .flexbox();
                .comment-author {
                    text-align: center;
                    margin-right: 30px;
                    .author-img {
                    }
                    .author-affiliation {
                    }
                    .author-name {
                    }
                    .author-stamp {
                        background:#f2f1f4;
                        width: auto;
                        padding: 0.3em 1em;
                        display: inline-block;
                        border-radius: 5px;
                        &:before {
                            background:url(/files/user/images/stamp-03.png) center 50%;
                            background-size: 20px;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            vertical-align: middle;
                            content:"";
                        }
                    }
                }
                @media screen and (max-width: @breakpoint) {
                    display: block;
                    .comment-author {
                        text-align: left;
                        padding-left: 70px;
                        min-height: 80px;
                        .author-img {
                            position: absolute;
                            top:0;
                            left:0;
                            width: 60px;
                        }
                        .author-affiliation,.author-name {
                            display: inline-block;
                            line-height: 1.5;
                        }
                        .author-stamp {
                            display: block;
                            width:8em;
                        }
                    }
                }
                .comment-main {
                    .dotBg(url(/files/user/images/bg-dot-pink.png));
                    .flex();
                    @media screen and (max-width: @breakpoint) {
                        display: block;
                    }
                    .comment-baloon {
                        .arrow_box_left();
                        padding: 25px;
                        @media screen and (max-width: @breakpoint) {
                            .arrow_box_top();
                            padding:15px 10px;
                        }
                        .comment-meta {
                            border-bottom: 1px solid black;
                            padding-bottom: 10px;
                            margin-bottom: 15px;
                            .flexbox();
                            align-items: center;
                            .alignitem-center();
                            .rate,.heart,.date {
                                line-height: 38px;
                                border-right: 1px solid black;
                                text-align: center;
                                @media screen and (max-width: @breakpoint) {
                                    line-height: 20px;
                                    padding: 0 3px;
                                }
                            }
                            .rate {
                                font-size: 24px;
                                font-weight:600;
                                width:30%;
                                text-align: left;
                                @media screen and (max-width: @breakpoint) {
                                    font-size: 14px;
                                    width:40%;
                                }
                                &:before {
                                }
                                @media screen and (max-width: @breakpoint) {
                                    &:before {
                                    }
                                }
                            }
                            .heart {
                                width:14%;
                                font-size: 16px;
                                font-weight:600;
                                &:before {
                                    background:url(/files/user/images/heart.png) center 50% no-repeat;
                                    background-size: 18px;
                                    display: inline-block;
                                    width:18px;
                                    height: 16px;
                                    vertical-align: middle;
                                    content:"";
                                    margin-right: 8px;
                                }
                                @media screen and (max-width: @breakpoint) {
                                    font-size: 14px;
                                    width: auto;
                                    &:before {
                                        background-size: 14px;
                                        margin-right: 4px;
                                        width:14px;
                                        height: 14px;
                                    }
                                }
                            }
                            .date {
                                width:20%;
                                font-size: 15px;
                                line-height: 1.3;
                                @media screen and (max-width: @breakpoint) {
                                    font-size: 12px;
                                    width: auto;
                                    span {
                                        display: inline-block;
                                    }
                                }
                            }
                            .like {
                                .flex();
                                text-align:right;
                                .like-img {
                                    width:88px;
                                }
                                .like-count {
                                    padding: 0 5px;
                                    display: inline-block;
                                    width: 3em;
                                    text-align: center;
                                    .arrow_box_lighter();
                                }
                                @media screen and (max-width: @breakpoint) {
                                    .like-img {
                                        width: 55px;
                                    }
                                    .like-count {
                                        font-size: 10px;
                                    }
                                }
                            }
                            .rate-txt {
                                border-right: 1px solid black;
                                .flex();
                                @media screen and (min-width: @breakpoint) {
                                    height: 38px;
                                    padding: 0.5em 0;
                                }
                            }
                        }
                        .comment-text {
                            padding-right: 140px;
                            position: relative;
                            .comment-img {
                                top:0;
                                right:0;
                                position: absolute;
                            }
                            a {
                                color:#1abcb8;
                                font-weight: 500;
                            }
                            p {
                                margin-bottom: 0.5em;
                                overflow:hidden;
                            }
                            .ttl {
                                font-size: 18px;
                                font-weight: 600;
                                margin-bottom: 0.5em;
                                text-align: left;
                            }
                            .btn_more {
                                color:#1abcb8;
                                background:none;
                                margin:0;
                                width: auto;
                                text-decoration: underline;
                                padding:0;
                                text-align: left;
                                cursor:pointer;
                            }
                            @media screen and (max-width: @breakpoint) {
                                padding-right:0;
                                .ttl {
                                    font-size: 15px;
                                }
                                .comment-img {
                                    float:right;
                                    margin-left: 5px;
                                    position: relative;
                                }
                                p {
                                    font-size: 13px;
                                }
                                
                            }
                        }
                    }
                }
            }
        }
        .noComment {
            position: relative;
            text-align: center;
            .btn_registration {
                position: absolute;
                bottom: 30px;
                left: 0;
                right:0;
                a {
                }
            }
            @media screen and (max-width: @breakpoint_tb) {
                img {
                    width: 100%;
                }
                margin: 15px;
            }
            @media screen and (max-width: @breakpoint) {
                img {
                    margin: 0 auto;
                }
                .btn_registration {
                    display: block;
                    width: 60%;
                    bottom: 5%;
                    a {
                        padding: 0.5em;
                        font-size: 14px;
                    }
                }
            }
        }
        .btn_more {
            .linkbtn();
            width:  200px;
        }
        .btn_registration {
            .linkbtn_border();
            width: 300px;
            @media screen and (max-width: @breakpoint) {
                display:none;
            }
        }
        .image {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            justify-items: stretch;
            align-items: stretch;
            margin: 10px 0;
            width: 100%;
            li {
                width: 200px;
                height: 200px;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            @media screen and (max-width: @breakpoint_tb) {
                //margin: 0 12px 10px;
                //grid-template-columns: 1fr 1fr 1fr 1fr ;
                li {
                    width: 21vw;
                    height: 21vw;
                }
            }
            @media screen and (max-width: @breakpoint) {
                //grid-template-columns: 1fr 1fr 1fr 1fr ;
                grid-column-gap: 2%;
                grid-row-gap: 2%;
                li {
                    width: 19vw;
                    height: 19vw;
                }
            }
        }
        &.mypage {
            .comments-list {
                .comment {
                    .comment-main {
                        .comment-baloon {
                            .comment-meta {
                                @media screen and (max-width: @breakpoint) {
                                    position: relative;
                                    display: block;
                                    .heart,
                                    .rate-txt {
                                        border: none;
                                    }
                                    .rate {
                                        width: 70%;
                                    }
                                    .heart {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                    }
                                    .rate-txt {
                                        margin: 10px 0 0 0;
                                        font-size: 13px;
                                        border-top: 1px solid black;
                                        padding-top: 0.5em;
                                    }
                                }
                            }
                            .comment-text {
                                padding-right: 0;
                            }
                            .date {
                                background: #f2f1f4;
                                border-radius: 5px;
                                padding: 0.4em 0;
                                width: 9em;
                                text-align: center;
                                margin: 10px 0;
                            }
                        }
                    }
                }
            }
        }
    }
/* more button */
    .btn_more {
        width:  200px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1em;
        margin-top: 2em;
        background: #ed797a;
        color:white;
        border-radius: 50px;
        font-size: 14px;
        text-align: center;
        padding: 0.5em 2em;
        cursor:pointer;
    }
    .btn_more + .more {
        display:none;
    }
    
/* footer */
.footer {
    .contact {
        background: #eeeeee;
        position: relative;
        .inner-in {
        padding: 26px 0;
        text-align: left;
        }
        p {
            color:#646464;
            font-size: 12px;
            a {
                color:#646464;
            }
        }
        #btn_top {
            display: block;
            height: 18px;
            width: 106px;
            background: url(/files/user/images/btn-top.png) no-repeat;
            position: absolute;
            top: 40%;
            right: 4%;
        }
        .footer-link1,
        .footer-link2,
        .footer-link3 {
            display:inline-block;
        }
        .footer-link2,
        .footer-link3 {
            a {
                text-decoration: none;
            }
        }
        .footer-link1 {
            font-size: 16px;
        }
        .footer-link2 {
        }
        .footer-link3 {
        }
    }
    .company {
        background: #f5f5f5;
        height: 134px;
        .inner-in {
            padding: 60px 0;
        }
        .logo {
            float: left;
        }
        .copyright {
            float: right;
        }
    }
    @media screen and (min-width: @breakpoint) {
        .contact {
            .footer-link1,
            .footer-link2,
            .footer-link3 {
                line-height: 40px;
                padding:0 1.5em;
                border-left: 1px solid #7d7d7d;
            }
            .footer-link3 {
                border-right: 1px solid #7d7d7d;
            }
        }
    }
    @media screen and (max-width: @breakpoint_tb) {
        .contact {
            .footer-link1,
            .footer-link2,
            .footer-link3 {
                padding:0 1em;
            }
            .footer-link1 {
                border-left: none;
                font-size: 15px;
            }
        }
    }
    @media screen and (max-width: @breakpoint) {
        .contact {
            .inner-in {
            text-align: center;
            padding: 20px 0;
            }
            font-size: 12px;
            #btn_top {
                display: block;
                margin: 0 auto 10px;
                position: relative;
                top: auto;
                right: auto;
            }
            .footer-link1 {
                display: block;
                font-size: 14px;
                margin: 0.5em 0;
            }
            .footer-link2 {
                border-right: 1px solid #7d7d7d;
            }
            .footer-link3 {
            }
        }
        .company {
            padding-bottom: 70px;
            height: auto;
            .inner-in {
                padding: 30px 0;
                text-align: center;
            }
            .logo {
                float: none;
            }
            .copyright {
                float: none;
            }
        }
    }
}


/* SPbottomArea */
.SPbottomArea {
    display:none;
    @media screen and (max-width: @breakpoint) {
        padding: 15px 10px ;
        display: block;
        position: fixed;
        bottom:0;
        background: white;
        width: 100%;
        z-index: 100;
        .flexbox();
        .btn_copy {
            margin:0;
            border-width: 3px;
            font-size: 12px;
            width: 120px;
            a {
                position: relative;
                padding-left: 24px;
                padding-top:0.5em ;
                padding-bottom:0.5em ;
                &:before {
                    position: absolute;
                    font-size: 20px;
                    left: 10px;
                }
            }
        }
        .btn_registration {
            .linkbtn_border();
            margin:0 20px 0 0;
            .flex();
            a {
                padding: 0.8em 0;
            }
        }
    }
}


/* modal */
.modal_box.situation {
    position: fixed;
    z-index: 7777;
    display: none;
    width: 840px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    text-align: left;
    background: #fff;
    box-sizing: border-box;
    border-radius: 15px;
    @media screen and (max-width: @breakpoint) {
        width: 90%;
        height: auto;
        width: 90%;
        height: auto;
        top: 20px !important;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
    }
}
.modal_box input:not([type="date"]) {
    display: none;
}
.modal_box .modal-ttl {
    text-align: center;
    padding: 18px 0;
    border-bottom: 1px solid;
    @media screen and (max-width: @breakpoint) {
        img {
            width: 90%;
            max-width: 385px;
        }
    }
}
.modal_box .tc {
    float: left;
    line-height: 30px;
    margin-right: 10px;
    padding: 9px;
    @media screen and (max-width: @breakpoint) {
        float:none;
        display: block;
        padding:0;
    }
}
.modal_box .des_sc_cont {
    float: left;
    display: flex;
    margin-right: 20px;
    @media screen and (max-width: @breakpoint) {
        margin-right: 10px;
    }
}
.modal_box .des_price_center {
    padding: 13px 7px;
    @media screen and (max-width: @breakpoint) {
        padding: 13px 1px;
    }
}
.modal_box #pplwListSel,
.modal_box #pphiListSel {
    width: 140px;
    display: flex;
    position: relative;
    border: 1px solid #000;
    @media screen and (max-width: @breakpoint) {
        width: 120px;
    }
}

.modal_box #pplwListSel .arw,
.modal_box #pphiListSel .arw {
    position: absolute;
    background: url(/files/user/images/arrow-02.png) no-repeat;
    width: 14px;
    height: 9px;
    top: 20px;
    right: 10px;
}

.modal_box .mdes_pplwListSel,
.modal_box .mdes_pphiListSel {
    padding: 12px 42px 12px 15px;
    @media screen and (max-width: @breakpoint) {
        padding: 10px 10px 10px 10px;
    }
}
.modal_box #pplwListSelBox,
.modal_box #pphiListSelBox {
    display: block;
    position: absolute;
    margin-top: 47px;
    background: #fff;
    width: 140px;
    z-index: 30;
    @media screen and (max-width: @breakpoint) {
        width: 120px;
        margin-top: 40px;
    }
}
.modal_box #pplwListSelBox li:hover,
.modal_box #pphiListSelBox li:hover {
    background: #ddd;
}
.modal_box #mpplwList,
.modal_box #mpphiList {
    border: 1px solid;
    max-height: 300px;
    overflow-x: hidden;
}
.modal_box #mpplwList li,
.modal_box #mpphiList li {
    width: 100%;
}
.modal_box .evt_click_param {
    padding: 8px 15px;
    display: block;
}
.modal_box .des_itemRow {
    font-size: 13px;
    padding: 15px 0;
    margin: 0 4%;
    border-bottom: 1px dotted #ccc;
    background-color: #fff;
    width: 92%;
    display: flex;
            @media screen and (max-width: @breakpoint) {
                padding: 10px 0;
                border:none;
                display: block;
                .clearfix();
                .des_itemTitle {
                    width: 100%;
                    float: none;
                    background:#f2f1f6;
                    text-align: center;
                    padding: 5px 0;
                    margin-bottom: 10px;
                }
                .des_item {
                }
                .des_box_drink4 {
                    float: none;
                    margin:0;
                }
            }
}
.modal_box .des_itemRow.last {
    border-bottom: none;
    @media screen and (max-width: @breakpoint) {
        .des_sc_cont {
            float:none;
        }
        display:block;
        float:none;
    }
}
.modal_box .des_itemTitle{
    width: 160px;
    vertical-align: top;
    color: #000;
    float: left;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    padding-left: 3px;
    padding-top: 18px;
    margin-right: 20px;
}
.modal_box .des_item {
    float: left;
    line-height: 22px;
    width: 900px;
    @media screen and (max-width: @breakpoint) {
        width: 100%;
    }
}
/*
.modal_box .des_item input + label {
    padding: 15px 15px 15px 35px;
    position:relative;
    display: inline-block;
    @media screen and (max-width: @breakpoint) {
        padding: 10px 10px 10px 35px;
    }
}
.modal_box .des_item input + label::before {
    content: "";
    display: block;
    background: url(/files/user/images/check-off.png) no-repeat left;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 13px;
    @media screen and (max-width: @breakpoint) {
        top: 8px;
    }
}
.modal_box .des_item input:checked + label::after{
    content: "";
    display: block;
    background: url(/files/user/images/check-on.png) no-repeat left;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 13px;
    @media screen and (max-width: @breakpoint) {
        top: 8px;
    }
}
*/
.modal_box .des_item li {
    float: left;
}
.modal_box .des_item .des_box_drink4 {
    display: inline-block;
}
.modal_box #bureauListSel,
.modal_box #departmentListSel {
    width: 200px;
    display: flex;
    position: relative;
    border: 1px solid #000;
    @media screen and (max-width: @breakpoint) {
        width: 100%;
        margin-bottom: 10px;
    }
}

.modal_box #bureauListSel .arw,
.modal_box #departmentListSel .arw {
    position: absolute;
    background: url(/files/user/images/arrow-02.png) no-repeat;
    width: 14px;
    height: 9px;
    top: 20px;
    right: 10px;
}

.modal_box .des_bureauListSel,
.modal_box .des_departmentListSel {
    padding: 12px 42px 12px 15px;
}
.modal_box #bureauListSelBox,
.modal_box #departmentListSelBox {
    display: block;
    position: absolute;
    margin-top: 47px;
    background: #fff;
    width: 200px;
    z-index: 30;
    @media screen and (max-width: @breakpoint) {
        width: 300px;
    }
}
.modal_box #bureauListSelBox li:hover,
.modal_box #departmentListSelBox li:hover {
    background: #ddd;
}
.modal_box #bureauList,
.modal_box #departmentList {
    border: 1px solid;
    max-height: 300px;
    overflow-x: hidden;
}
.modal_box #bureauList li,
.modal_box #departmentList li {
    width: 100%;
}
.modal_box .submit {
    background: #ed797a;
    padding: 30px 0;
    text-align: center;
    @media screen and (max-width: @breakpoint) {
        padding: 15px 0;
        img {
            width: 120px;
        }
    }
}

.modal_box.excuse {
    position: fixed;
    z-index: 7777;
    display: none;
    overflow: hidden;
    margin: 0;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
    border-radius: 15px;
    &.excuse1-3 {
        .mincho {    
            font-size: 22px;
            font-weight: 600;
            line-height: 1.8;
            margin-top: 1em;
            @media screen and (max-width: @breakpoint) {
                font-size: 3vw;
            }
        }
    }
    &.excuse4 {
        width: 530px;
        height: 530px;
        background: #fff;
        p {
            margin-top: -60px;
            line-height: 1.5;
            @media screen and (max-width: @breakpoint) {
                margin-top: -15%;
                font-size: 13px;
            }
        }
        .onegai {
            margin-top: 20px;
            font-size: 1.4em;
            @media screen and (max-width: @breakpoint) {
                margin-top: 10px;
                font-size: 1.2em;
            }
        }
    }
    @media screen and (max-width: @breakpoint) {
        width: 90%;
        height: 70%;
        .inner_modal {
            padding: 10px 15px;
        }
    }
}
.modal_box.excuse .modal-ttl {
    background: none;
    padding: 50px 0 0;
    border: none;
    @media screen and (max-width: @breakpoint) {
        padding-top: 10%;
    }
}
.modal_bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6666;
    display: none;
    width: 100%;
    height: 120%;
    background-color: rgba(0,0,0,0.7);
    &.white {
        background-color: rgba(255,255,255,0.95);
    }
}

.popup-form .mfp-content {
    border-radius: 20px;
    border: 5px solid black;
    overflow:hidden;
    background: white;
    @media screen and (min-width: @breakpoint) {
        height: 799px;
    }
}
.mfp-content {
    .close-modal {
        top: 25px;
        right: 25px;
        display: block;
        background:url(/files/user/images/close-modal.png) no-repeat center top;
        width: 27px;
        height: 27px;
        z-index: 100;
        @media screen and (max-width: @breakpoint) {
            top:10px;
            right: 10px;
            img {
                width: 20px;
            }
        }
    }
    .mfp-close.close-modal_white {
        display: block;
        background:url(/files/user/images/close-modal_white.png) no-repeat center top;
        width: 27px;
        height: 27px;
    }
}


@import "../_less/page-top.less";
@import "../_less/page-shop.less";
@import "../_less/page-shop-list.less";
@import "../_less/page-map.less";
@import "../_less/page-form.less";
@import "../_less/page-mypage.less";
@import "../_less/page-news.less";
@import "../_less/page-special.less";




/* ---- add ---- */
@media screen and (max-width: 768px) {
  .sp_disp_none {
    display: none;
  }
}
.genrSwitchWrap {
 position: relative;
}
.genrSwitchWrap .tabSwitch {
 position: relative;
 margin: 0;
 display: -ms-flexbox;
 display: -webkit-box;
 display: -webkit-flex;
 display: flex;
}
.genrSwitchWrap .tabSwitch li {
 border-top: 2.5px solid #000;
 border-left: 2.5px solid #000;
 border-right: 2.5px solid #000;
 border-radius: 10px 10px 0 0;
 font-size: 12px;
 width: 280px;
 margin-right: 15px;
 padding: 15px 0;
 text-align: center;
 background: #FFF;
 cursor: pointer;
 position: relative;
}
@media screen and (max-width: 768px) {
  .genrSwitchWrap .tabSwitch li {
    width: 48%;
  }
}
.genrSwitchWrap .tabSwitch li.active:after{
 content: "";
 display: block;
 width: 100%;
 height: 2.5px;
 background: #FFF;
 position: absolute;
 bottom: -2.5px;
 z-index: 2;
}
.genrSwitchWrap .tabSwitch li.disabled {
 border-color: #c3c3c4;
 color: #c3c3c4;
 cursor: auto;
}
.genrSwitchWrap .nts {
 position: absolute;
 right: 0;
 top: 5px;
 left: 590px;
 padding-left: 50px;
}
@media screen and (max-width: 768px) {
  .genrSwitchWrap .nts {
    position: static;
    padding-left: 30px;
  }
}
.genrSwitchWrap .nts:before {
 position: absolute;
 content: "";
 width: 0;
 height: 0;
 border: 10px solid transparent;
 border-style: solid;
 border-color: transparent #ed797a transparent transparent;
 border-right-width: 20px; 
 left: 0px;
 top: 50%;
 transform: translateY(-50%);
 z-index: 2;
}
@media screen and (max-width: 768px) {
  .genrSwitchWrap .nts:before {
    border-color: #ed797a transparent transparent transparent;
    border-right-width: 10px;
    border-top-width: 20px; 
    top: 25px;
  }
}
.tabContentWrap {
 padding: 38px 30px;
 border-top: 2.5px solid #000;
 background: #FFF;
}
@media screen and (max-width: 768px) {
  .tabContentWrap {
    padding: 10px 0;
  }
}
.tabContentWrap .tabCnt {
 display: none;
}
.tabContentWrap .tabCnt.active {
 display: block;
}
.tabContentWrap .tabCnt .genrCheckList {
 display: -webkit-box;
 display: -webkit-flex;
 display: flex;
 flex-wrap:wrap;
 -webkit-flex-wrap:wrap;
}
.tabContentWrap .tabCnt .genrCheckList li {
 width: 33%;
}
@media screen and (max-width: 768px) {
  .tabContentWrap .tabCnt .genrCheckList li {
    width: 50%;
  }
}
.tabContentWrap .tabCnt .details {
 margin-bottom: 40px;
 display: none;
}
.tabContentWrap .tabCnt h3.mi {
 font-size: 20px;
 line-height: 2em;
 border-bottom: 1px solid #e4e4e4;
 margin-bottom: 10px;
}
