.arrow_box() {
	position: relative;
	background: #ffffff;
	border: 3px solid #000000;
    border-radius: 10px;
    &:after, &:before {
    	bottom: 100%;
    	left: 95px;
    	border: solid transparent;
    	content: " ";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    }
    &:after {
    	border-color: rgba(255, 255, 255, 0);
    	border-bottom-color: #ffffff;
    	border-width: 8px;
    	margin-left: -8px;
    }
    &:before {
    	border-color: rgba(0, 0, 0, 0);
    	border-bottom-color: #000000;
    	border-width: 12px;
    	margin-left: -12px;
    }
}


.mypage-profile {
    .inner-in {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    
    .profile_txt {
        float:right;
        width: 75%;
        .name {
            font-size: 18px;
            margin-bottom: 0.4em;
            span {
                font-size: 30px;
            }
        }
        .sub {
            display: inline-block;
            background:#f1f1f1;
            border-radius: 30px;
            padding: 0.2em 1em;
        }
        @media screen and (max-width: @breakpoint) {
            .name {
                font-size: 16px;
                span {
                    font-size: 20px;
                }
            }
            .sub {
                font-size: 13px;
            }
        }
    }
    .navigationArea {
        padding: 30px 0 15px 0;
        border-top: 1px solid #000;
        margin: 30px 0;
        @media screen and (min-width: @breakpoint) {
            float:right;
            width: 75%;
        }
        @media screen and (max-width: @breakpoint) {
            clear: both;
            border:none;
            padding-top: 15px;
            margin: 15px 0;
        }
        .navigation {
            .flexbox();
            align-items: stretch;
        }
        .comment,.photo,.like, .info {
            border-left: 1px solid #101012;
            font-weight: 500;
            width: 25%;
            line-height: 1.2;
            @media screen and (max-width: @breakpoint_tb) {
            }
            a {
                display: block;
                text-decoration: none;
                font-size: 16px;
                text-align: center;
                width: 100%;
                color: black;
                width: 100%;
                padding: 1em 0;
                &:before {
                    font-family:@icon;
                    padding-right: 0.5em;
                    font-size: 18px;
                }
                @media screen and (max-width: @breakpoint_tb) {
                    font-size: 14px;
                }
                @media screen and (max-width: @breakpoint) {
                    font-size: 12px;
                    padding: 0.7em 0;
                    letter-spacing: -0.05em;
                    &:before {
                        font-size: 13px;
                        padding-right: 0.2em;
                    }
                }
            }
        }
        .comment {
            border-right: 1px solid #101012;
            a:before {
                content: "\e903";
            }
        }
        .photo {
            a:before {
                content: "\e902";
            }
        }
        .info {
            a:before {
                background: url(/files/user/images/icon_bell.png) center 50% no-repeat;
                background-size: 16px;
                display: inline-block;
                width: 16px;
                height: 20px;
                vertical-align: middle;
                content: "";
                margin-right: 4px;
            }
            @media screen and (max-width: @breakpoint) {
                a:before {
                    background-size: 12px;
                    width: 12px;
                    height: 16px;
                    margin-right: 2px;
                }
            }
        }
        .like {
            a:before {
                background: url(/files/user/images/heart.png) center 50% no-repeat;
                background-size: 18px;
                display: inline-block;
                width: 18px;
                height: 16px;
                vertical-align: middle;
                content: "";
                margin-right: 4px;
            }
            @media screen and (max-width: @breakpoint) {
                a {
                    padding: 0.3em 0;
                }
                a:before {
                    background-size: 11px;
                    width: 11px;
                    height: 14px;
                    margin-right: 4px;
                }
            }
        }
    }
    #profile_icon {
        position: relative; 
        float: left;
        width: 22%;
        max-width: 196px;
        @media screen and (min-width: @breakpoint) {
        }
        #icon_image {
            img {
                width: 100%;
            }
        }
        #icon_menu,
        #icon_choice {
            z-index: 1100;
            display: none;
            position: absolute;
            top: 105%;
        }
        #icon_menu {
            text-align: center;
            .fukidashi {
                .arrow_box();
            }
            .choice,.upload,.delete,.cancel {
                width: 200px;
            }
            .choice,.upload,.cancel {
                cursor: pointer;
                padding: 0.8em 0;
                &:hover {
                    background: #ed797a;
                    color: white;
                }
            }
            .delete {
                a {
                    padding: 0.8em 0;
                    text-decoration: none;
                    color: black;
                    display: block;
                    &:hover {
                        background: #ed797a;
                        color: white;
                    }
                }
            }
            .cancel {
                border-top: 1px solid #000;
            }
            @media screen and (max-width: @breakpoint) {
                font-size: 13px;
                .fukidashi {
                    &:after, &:before {
                    	left: 20%;
                    }
                }
                .choice,.upload,.delete,.cancel {
                    width: 180px;
                }
            }
        }
        #icon_choice {
            width: 900px;
            .fukidashi {
                padding: 30px;
            }
            .icon_ul {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr;
                grid-column-gap: 20px;
                grid-row-gap: 20px;
                justify-items: stretch;
                align-items: stretch;
                li.icon_li {
                    a {
                        display: block ;
                        padding: 10px;
                        &:hover {
                            background: #fad9d8;
                            border-radius: 10px;
                            img {
                                opacity:1;
                            }
                        }
                    }
                }
            }
            .close {
                background: #ed797a;
                border-radius: 30px;
                width: 12em ;
                text-align: center;
                margin: 2em auto 0;
                padding: 0.4em 0;
                color: white;
            }
            @media screen and (max-width: @breakpoint_tb) {
                width:90vw ;
            }
            @media screen and (min-width: @breakpoint) {
                .fukidashi {
                    .arrow_box();
                }
            }
            @media screen and (max-width: @breakpoint) {
                position: fixed;
                top: 0;
                width: 100vw;
                background: white;
                height: 100vh;
                overflow-y:scroll;
                left:0;
                .icon_ul {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    grid-template-rows: 1fr;
                    grid-column-gap:15px;
                    grid-row-gap: 15px;
                    li.icon_li {
                        a {
                            padding:0;
                        }
                    }
                }
            }
        }
    }
/* info */
.infoArea {
    clear:both;
    font-size: 14px;
    border: 3px solid black;
    border-radius: 15px ;
    .dotBg(url(/files/user/images/bg-dot-yellow.png));
    .news_ttl {
        background: #000;
        text-align: center;
        font-size:22px;
        font-family:@fontMincho;
        padding: 0.4em 0;
        color:white;
        border-radius: 12px 12px 0 0 ;
            &:before {
                background: url(/files/user/images/icon_bell.png) center 50% no-repeat;
                background-size: 16px;
                display: inline-block;
                width: 16px;
                height: 20px;
                vertical-align: middle;
                content: "";
                margin-right: 4px;
            }
            @media screen and (max-width: @breakpoint) {
                font-size: 18px;
                a:before {
                    background-size: 12px;
                    width: 12px;
                    height: 16px;
                    margin-right: 2px;
                }
            }
    }
    .news_cont {
        border-radius: 0 0 12px 12px;
        background:white;
        padding: 10px 20px 0;
        position: relative
    }
    dl {
        dt {
            position: absolute;
        }
        dd {
            padding-left: 9em;
            padding-bottom: 1em;
        }
    }
    .btn_more {
        background: #e7e7e7;
        position:relative;
        border-radius: 10px 10px 0 0 ;
        margin:0 auto;
        color:black;
        width: 10em;
        font-size: 12px;
        padding-left: 0;
        padding-right:0;
        &:after {
                content: "\e90a";
                font-family:@icon;
                font-size: 14px;
                vertical-align: middle;
                position:absolute;
                color:#2eb6aa;
                right: 0.5em;
                top: 50%;
                margin-top: -10px;
                
        }
    }
    @media screen and (min-width: @breakpoint) {
        .news_link {
            position: absolute;
            top: -55px;
            .linkbtn();
            border-top:none;
            padding:0;
            right:0;
        }
    }
    @media screen and (max-width: @breakpoint) {
        font-size: 12px;
        dl {
            dt {
                position:relative;
                &:after {
                }
            }
            dd {
                padding:0 0 0.5em 0;
                border-bottom: 1px dotted #bbb;
            }
        }
        .btn_more {
            margin-top: 5px;
        }
    }
}

}
.favorit-list {
    background:url(/files/user/images/bg-mypage.png) no-repeat center top #fff75b;
    @media screen and (max-width: @breakpoint) {
        background-size: 80%;
        background-position: ;
    }
    .inner-in {
        padding-top: 30px;
        padding-bottom: 30px;
        @media screen and (max-width: @breakpoint) {
            background-position:  45% -50px;
            background-size: 110%;
        }
    }
    .ttl {
        text-align: center;
        margin-bottom: 1em;
        @media screen and (max-width: @breakpoint) {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .favorit-data {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        justify-items: stretch;
        align-items: stretch;
        @media screen and (max-width: @breakpoint_tb) {
            grid-column-gap: 10px;
            grid-row-gap: 10px;
        }
        @media screen and (max-width: @breakpoint) {
            grid-template-columns: 1fr 1fr;
        }
        .favorit-frame {
            border: 2.5px solid #000;
            background: #fff;
            border-radius: 15px;
            position: relative;
            &.new {
                &:before {
                    background:url(/files/user/images/icon_new.png) no-repeat;
                    background-size: 100%;
                    content: "";
                    display: block;
                    width: 52px;
                    height: 52px;
                    position:absolute;
                    top:-3px;
                    left:-3px;
                }
            }
            a {
                color: #000;
                text-decoration: none;
            }
            .image {
                border-radius: 13px 13px 0 0;
                overflow: hidden;
            }
            .tag {
                padding: 10px 15px 0 15px;
                min-height: 3.3em;
                font-size: 14px;
                line-height: 1.3;
                @media screen and (max-width: @breakpoint) {
                    font-size: 12px;
                    padding: 10px 10px 0 10px;
                }
            }
            .shopname {
                padding: 0 15px;
                margin: 0;
                height: 67px;
                line-height: 1.3;
                text-align: left;
                font-size: 17px;
                font-weight: 600;
                @media screen and (max-width: @breakpoint) {
                    font-size: 14px;
                    padding: 0 10px;
                }
            }
            .image {
                img {
                    width: 100%;
                }
            }
        }
    }
    
    
}