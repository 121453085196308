
.mapArea {
    background: #f5f5f5;
    padding-top: 40px;
    padding-bottom: 30px;
    @media screen and (max-width: @breakpoint) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .map-ttl {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 0.5em;
        @media screen and (max-width: @breakpoint) {
            font-size: 20px;
        }
    }
    &.fixed {
        position:fixed;
        top:0;
        width: 100%;
        z-index:300;
    }
}
.gmap {
    height: 400px;
    border: 3px solid black;
    border-radius: 15px;
    @media screen and (max-width: @breakpoint) {
        height: 300px;
    }
    .gmap-info-window {
        position: relative;
        width: 320px;
        padding: 8px 15px;
        border: 3px solid #000;
        border-radius: 10px;
        background-color: #fff;
        &:before {
            display: inline-block;
            position: absolute;
            left: 39%;
            bottom: -18px;
            width: 20px;
            height: 18px;
            transform: translate(-50%, 0);
            background: url(/files/user/images/gmap-baloon.png) no-repeat 0 0;
            content: '';
        }
        @media screen and (max-width: @breakpoint) {
            padding: 10px;
            width: 270px;
            &:before {
                background-size: 60%;
                left:48%;
            }
        }
    }
}

.gmap-info-window
.gmap-info-window-left {
  float: left;
  width: 60%;
  @media screen and (max-width: @breakpoint) {
      width: 60%;
  }
}
.gmap-info-window-distance {
    color: #9f9f9f;
    font-size: 12px;
    text-align: left;
}
.gmap-info-window-shopname {
    margin-top: 5px;
    font-size: 16px;
      @media screen and (max-width: @breakpoint) {
          font-size: 13px;
      }
}
.gmap-info-window-closed,
.gmap-info-window-yosan {
    margin: 0;
    font-size: 12px;
    @media screen and (max-width: @breakpoint) {
        font-size: 11px;
    }
}
.gmap-info-window-link {
    position: absolute;
    bottom: 15px;
    @media screen and (max-width: @breakpoint) {
        bottom: 5px;
        right: 15px;
    }
}
.gmap-info-window-link a {
  color: #2eb6aa;
    font-size: 12px;
  text-decoration: underline;
}
.gmap-info-window-image {
  width: 90px;
  height: auto;
  float: right;
  img {
      width: 100%;
  }
  @media screen and (max-width: @breakpoint) {
      width: 100%;
  }
}
.gmap-info-window-right {
  float: right;
  width: 40%;
  @media screen and (max-width: @breakpoint) {
    width: 30%;
  }
}

