
/* form decoration */
.form_style {
    input[type=checkBox],
    input[type=radio] {
        display:none;
    }
    label:hover {
        cursor: pointer;
    }
    label.checkBoxlabel,
    label.radiolabel {
        padding: 10px 8px 10px 24px;
        position: relative;
        display: inline-block;
        @media screen and (max-width: @breakpoint) {
            padding: 8px 8px 8px 24px;
        }
    }
    label.checkBoxlabel::before,
    label.radiolabel::before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 9px;
        @media screen and (max-width: @breakpoint) {
            width: 18px;
            height: 18px;
        }
    }
    input:checked + label.checkBoxlabel::after,
    input:checked + label.radiolabel::after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 9px;
        @media screen and (max-width: @breakpoint) {
            width: 18px;
            height: 18px;
        }
    }
    label.checkBoxlabel::before {
        background: url(/files/user/images/check-off2.png) no-repeat left;
        background-size: 100%;
    }
    input:checked + label.checkBoxlabel::after {
        background: url(/files/user/images/check-on.png) no-repeat left;
        background-size: 100%;
    }
    label.error.checkBoxlabel::before {
        background: url(/files/user/images/check-error.png) no-repeat left;
        background-size: 100%;
    }
    label.radiolabel::before {
        background: url(/files/user/images/radio-off.png) no-repeat left;
        background-size: 100%;
    }
    input:checked + label.radiolabel::after {
        background: url(/files/user/images/radio-on.png) no-repeat left;
        background-size: 100%;
    }
    label.error.radiolabel::before {
        background: url(/files/user/images/radio-error.png) no-repeat left;
        background-size: 100%;
    }
    .text, textarea {
        border:1px solid #dfdfdf;
        padding: 10px;
        color: #333;
        background: #fff;
        box-shadow:0px 1px 2px 0px #eee inset;
        margin: 5px 0;
        max-width: 100%;
        &.w_full {
            width: 100%;
        }
        &.w_1 {
            width: 62%;
        }
        &.w_2 {
            width: 30%;
        }
    }
    .selectBox {
        margin: 5px 0;
        &:after {
            font-family:@icon;
            content: "\e90a";
        }
    }
    .form-selectBox-wrap {
        position:relative;
        overflow:hidden;
        display:inline-block;
        min-width:192px;
        min-width:12em;
        background-color:#ffffff;
        color:#333;
        border:1px solid #dfdfdf;
        box-shadow:0px 1px 2px 0px #eee inset;
        vertical-align: middle;
        margin: 5px 0;
    }
    .selectBox {
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        position:relative;
        z-index:2;
        display:block;
        width:200%;/* fallback non calc support */
        width:-webkit-calc(~'100% + 5em');
        margin:0;
        padding:8px 35px 8px 11px;
        background:transparent;
        border:0;
        outline:none;
        font-size:13px;
        line-height:1.5;
        &.fl {
            font-size: 18px;
        }
    }

    .entypo-down-open:before{
        content: "\e90a";
        font-family:@icon;
        position:absolute;
        z-index:1;
        top:50%;
        right:12px;
        right:.75rem;
        margin-top:-8px;
        margin-top:-.5rem;
        font-size:13px;
        font-size:1rem;
        line-height:1;
        color:#999;
    }
    .text,
    textarea,
    .form-selectBox-wrap,
    input[type="date"] {
        &.error {
            background: #ffe6ec;
        }
    }
    div.error {
        background: #ffe6ec;
        border-radius: 10px;
        padding: 10px;
    }
    ul.inline-list {
        li {
            display: inline-block;
        }
    }
}

.modal_box.situation .form_style,
.modal_box.review-form .form_style {
    label.checkBoxlabel,
    label.radiolabel {
        padding-left: 28px;
    }
    label.checkBoxlabel::before,
    label.radiolabel::before {
        width: 24px;
        height: 24px;
    }
    input:checked + label.checkBoxlabel::after,
    input:checked + label.radiolabel::after {
        width: 24px;
        height: 24px;
    }
    label.checkBoxlabel::before {
        background: url(/files/user/images/check-off.png) no-repeat left;
        background-size: 100%;
    }
    input:checked + label.checkBoxlabel::after {
        background: url(/files/user/images/check-on.png) no-repeat left;
        background-size: 100%;
    }
    input[type=date]::-webkit-inner-spin-button,
    input[type=time]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .form-selectBox-wrap {
        color:#000;
        border:1px solid #000000;
        box-shadow:0px 0 0 0px #fff inset;
    }
    dl.modal-form {
        margin: 0 4%;
        background-color: #fff;
        width: 92%;
        dt {
            color: #000;
            font-weight: 700;
            position: absolute;
            padding: 25px 0;
            font-size: 16px;
        }
        dd {
            padding: 15px 0 15px 9em;
            border-bottom: 1px dotted #ccc;
            font-size: 14px;
            min-height: 3.5em;
            &:last-child {
                border:none;
            }
        }
    }
    @media screen and (max-width: @breakpoint) {
        dl.modal-form {
            dt {
                font-size: 14px;
            }
            dd {
                font-size: 13px;
            }
        }
    }
}

.form {
    background-image: url(/files/user/images/bg-form.png) ,url(/files/user/images/bg-form.png);
    background-repeat:no-repeat,no-repeat;
    background-position:86% 100px,20% 98%;
    .ttl {
        background:#f2f1f4;
        padding: 30px 0;
        .form_ttl {
            font-size: 20px;
            font-weight: 500;
        }
        @media screen and (max-width: @breakpoint) {
            padding: 20px 0;
            .form_ttl {
                font-size: 18px;
            }
        }
    }
    .formArea {
        border: 2.5px solid #000;
        border-radius: 15px;
        background: white;
        padding: ;
        overflow: hidden;
        position: relative;
        margin-bottom: 50px;
        .form_head {
            background: #fff75b;
            font-size: 18px;
            font-weight: 600;
            padding: 1em 30px;
            position: relative;
            z-index:1;
        }
        .notes {
            text-align:right;
            top: 30px;
            right: 20px;
            position: absolute;
            z-index:2;
            font-size: 13px;
        }
        @media screen and (max-width: @breakpoint) {
            margin-bottom: 30px;
            .form_head {
                font-size: 17px;
                padding: 1em 20px;
            }
            .notes {
                position: relative;
                top:0;
                right:0;
                text-align: center;
                font-size: 12px;
                margin: 1em 0.5em;
            }
        }
    }
    .errorTxt {
        color: #ff0000;
    }
    .form_table {
        margin: 50px 5%;
        width:90%;
        border-collapse: collapse;
        th,
        td {
            padding: 15px 15px;
            border-bottom: 1px solid #cccccc;
            border-top: 1px solid #cccccc;
        }
        th {
            text-align: left;
            font-weight: 500;
            background: #f2f1f4;
            width: 14em;
        }
        td {
            font-size: 13px;
        }
        @media screen and (max-width: @breakpoint) {
            display: block;
            margin: 30px 0;
                width:100%;
            tbody,tr {
                display: block;
                width: 100%;
            }
            th,
            td {
                display: block;
                width: 100%;
                border:none;
                padding: 10px;
            }
        }
    }
    .btn-wrap {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 80px;
        .btn,
        .btn2 {
            color:white;
            text-decoration: none;
            border-radius: 15px;
            font-size: 16px;
            text-align: center;
            padding: 1.2em 4em;
            width: auto;
            display: inline-block;
            margin: 5px;
            line-height: 1.2em;
            border:none;
            .dotBg();
            &:after {
                bottom: -6px;
                right: -6px;
            }
        }
        .btn {
            background: #ed797a;
        }
        .btn2 {
            background: #a6a6a6;
        }
    }
}

.modal_box,
.form {
        .thanks {
            text-align: center;
            padding: 100px 0;
            .thanks_txt {
                margin: 1.5em 0;
                font-weight: 600;
                font-size: 18px;
                line-height: 2;
            }
            @media screen and (max-width: @breakpoint) {
                padding: 40px 15px;
                .thanks_txt {
                    font-size: 15px;
                    line-height: 1.7;
                }
            }
        }
}

.registration_form {
    .formArea {
        margin-top: 40px;
    }
    .lead {
        margin:  2em 0;
    }
    .btn-wrap {
        .btn,
        .btn2 {
            width: 18em;
        }
    }
    .row {
        .flexbox();
        justify-content: flex-start;
        .file-photo {
            margin: 1%;
            img {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: @breakpoint) {
        .formArea {
            margin-top: 20px;
        }
    }
}
.inquiry_form {
    .formArea {
        margin-top: 50px;
    }
    .btn-wrap {
        .btn,
        .btn2 {
            width: 18em;
            padding: 1.2em 3em;
        }
    }
    @media screen and (max-width: @breakpoint) {
        .formArea {
            margin-top: 20px;
        }
    }
}


// iframe mordal

.modal_box.review-form {
    display:block;
    width: 100%;
    margin: 0;
    padding: 30px 0 0;
    background: #fff;
    box-sizing: border-box;
    .modal-ttl {
        background: url(/files/user/images/review-ttl.png) no-repeat center 50%;
        @media screen and (max-width: @breakpoint) {
            background-size: 80%;
        }
    }
    .form_style {
        .errorTxt {
            color: #ff0000;
        }
        dl.modal-form {
            margin-bottom: 2em;
            dt {
                font-size: 15px;
                padding: 15px 0;
            }
            dd {
                text-align: left;
                padding-left: 180px;
                min-height: 3.5em;
                &.noborder {
                    border: none;
                    padding-bottom:0;
                }
                &.full_width {
                    padding-left:0;
                }
                &.rate-main {
                    p {
                        display: inline-block;
                        font-size: 20px;
                    }
                }
            }
        }
        input[type="text"],
        input[type="file"]{
            display: block;
        }
        .form-selectBox-wrap {
            border: 1px solid #dfdfdf;
            box-shadow: 0px 1px 2px 0px #eee inset;
        }
        input[type="date"]{
            border: 1px solid #dfdfdf;
            box-shadow: 0px 1px 2px 0px #eee inset;
            padding: 0.5em 1em;
        }
        .form-selectBox-wrap.form-rate {
            min-width: 5em;
        }
        .row {
            .flexbox();
            .file-photo {
                margin: 1%;
                img {
                    width: 100%;
                }
            }
        }
        .row.date {
            justify-content: flex-start;
            .col {
                margin-right: 10px;
            }
            .form-selectBox-wrap {
                min-width: 5em;
            }
        }
    }
    @media screen and (max-width: @breakpoint) {
        padding-top: 00px;
        .form_style {
            dl.modal-form {
                margin-top: 1em;
                margin-bottom: 2em;
                dt {
                    font-size: 14px;
                    width: 100%;
                    position: relative;
                    background: #f2f1f6;
                    text-align: center;
                    padding: 5px 0;
                }
                dd {
                    padding-left: 0;
                    border: none;
                    padding: 10px 0 15px;
                    &.rate-main {
                        .rate_img {
                            width: 68%;
                        }
                    }
                    &.full_width {
                        padding-left:0;
                    }
                }
            }
        }
    }
    .rate_img {
        margin-right: 5px;
        display:inline-block;
    }
    .des_item_sub {
        background:#f5f4f6;
        padding: 10px;
        border-radius: 10px;
        clear:both;
        ul {
            .flexbox();
            align-items: center;
            flex-wrap: wrap;
            li {
                width:100/3-1%;
                align-items: center;
                margin-right: 1%;
                .flexbox();
                &:last-child {
                    margin-right: auto;
                }
                h4 {
                    width: 5em;
                }
                .rate_img {
                    width: 100px;
                    img {
                        width: 100%;
                    }
                }
                .selectBox {
                    padding:4px 35px 4px 11px;
                }
            }
        }
        @media screen and (max-width: @breakpoint) {
            ul {
                display:block;
                li {
                    width: 100%;
                }
            }
        }
    }
    .submit {
        @media screen and (max-width: @breakpoint) {
            padding: 15px 0;
            img {
                width: 150px;
            }
        }
    }
}